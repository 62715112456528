import { notification } from 'antd';
import PropTypes from 'prop-types';

export const notify = (error, title, setLoading = () => {}, setError = () => {}) => {
  setError(true);
  notification.error({ message: title, description: error.message });
  setLoading(false);
};

notify.propTypes = {
  error: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  setLoading: PropTypes.func,
  setError: PropTypes.func,
};

notify.defaultProps = {
  title: 'Error',
  setLoading: () => null,
  setError: () => null,
};

export const Error = {
  notify,
};

export default Error;

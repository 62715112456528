/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFlextimeForAdmins = /* GraphQL */ `
  subscription OnCreateFlextimeForAdmins {
    onCreateFlextimeForAdmins {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlextimeForAdmins = /* GraphQL */ `
  subscription OnUpdateFlextimeForAdmins {
    onUpdateFlextimeForAdmins {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProjectForAdmins = /* GraphQL */ `
  subscription OnCreateProjectForAdmins {
    onCreateProjectForAdmins {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProjectForAdmins = /* GraphQL */ `
  subscription OnUpdateProjectForAdmins {
    onUpdateProjectForAdmins {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConnector = /* GraphQL */ `
  subscription OnCreateConnector($owner: String!) {
    onCreateConnector(owner: $owner) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConnector = /* GraphQL */ `
  subscription OnUpdateConnector($owner: String!) {
    onUpdateConnector(owner: $owner) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConnector = /* GraphQL */ `
  subscription OnDeleteConnector($owner: String!) {
    onDeleteConnector(owner: $owner) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFlextime = /* GraphQL */ `
  subscription OnCreateFlextime($owner: String) {
    onCreateFlextime(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFlextime = /* GraphQL */ `
  subscription OnUpdateFlextime($owner: String) {
    onUpdateFlextime(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFlextime = /* GraphQL */ `
  subscription OnDeleteFlextime($owner: String) {
    onDeleteFlextime(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String) {
    onCreateProject(owner: $owner) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String) {
    onUpdateProject(owner: $owner) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String) {
    onDeleteProject(owner: $owner) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTimeEntry = /* GraphQL */ `
  subscription OnCreateTimeEntry($owner: String) {
    onCreateTimeEntry(owner: $owner) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTimeEntry = /* GraphQL */ `
  subscription OnUpdateTimeEntry($owner: String) {
    onUpdateTimeEntry(owner: $owner) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTimeEntry = /* GraphQL */ `
  subscription OnDeleteTimeEntry($owner: String) {
    onDeleteTimeEntry(owner: $owner) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;

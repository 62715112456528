import React, { useContext, useState } from 'react';
import {
  Drawer, Skeleton, PageHeader, Typography,
} from 'antd';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import UserContext from '../../Context/User/UserContext';
import FlextimesTable from './FlextimesTable';
import FlextimeForm from './FlextimeForm';

const { Title } = Typography;

const FlextimeCard = () => {
  const { loaded } = useContext(UserContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [model, setModel] = useState({});

  const edit = (event) => {
    setModel({
      ...event,
      createdAt: moment(event.createdAt),
      updatedAt: moment(event.updatedAt),
    });
    setDrawerVisible(true);
  };

  return (
    <Skeleton loading={!loaded}>
      <PageHeader
        title={(<Title level={3}>Today&apos;s activity</Title>)}
        bordered={false}
        style={{ width: '100%', overflow: 'auto' }}
        bodyStyle={{ padding: 0 }}
      >
        <FlextimesTable onClickEdit={edit} />
        <Drawer
          title="Edit record"
          placement="right"
          width={isMobile ? '80%' : '40%'}
          closable
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          destroyOnClose
        >
          <FlextimeForm afterSubmit={() => setDrawerVisible(false)} model={model} />
        </Drawer>
      </PageHeader>
    </Skeleton>
  );
};

export default FlextimeCard;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeWindowContext from './TimeWindowContext';

const FAKE_TIME_WINDOW_MODEL = {
  date: moment('2020-05-11'), // An example date that matches one payment date as moment instance
  period: { // Frequency of payment cycles
    amount: 2,
    unit: 'weeks',
  },
};

const TimeWindowProvider = ({ children }) => {
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment());
  const [model, setModel] = useState({});
  const [days, setDays] = useState(0);

  const daysOnTimeWindow = (twModel) => {
    const { date, period: { amount, unit } } = twModel;
    return Math.abs(date.diff(date.clone().add(amount, unit), 'days'));
  };

  const setStartAndEnd = (startDate) => {
    setStart(startDate);
    setEnd(startDate.clone().add(days - 1, 'days').endOf('day'));
  };

  const isValidDate = (date) => {
    const { date: modelStart } = model;
    if (modelStart) {
      const diffInDays = modelStart.startOf('day').diff(date.startOf('day'), 'days');
      return date.isBefore(moment().endOf('day')) && diffInDays % days === 0;
    }
    return true;
  };

  const findNearestStartDate = (date) => {
    const startDate = date.clone();

    if (!days) {
      throw new Error('cannot search when no days difference exists');
    }

    while (!isValidDate(startDate)) {
      startDate.subtract(1, 'days');
    }

    return startDate;
  };

  function disabledDate(date) {
    return !isValidDate(date);
  }

  useEffect(() => {
    const fetchData = () => setTimeout(async () => {
      setModel(FAKE_TIME_WINDOW_MODEL);
      setDays(daysOnTimeWindow(FAKE_TIME_WINDOW_MODEL));
    }, 200);

    fetchData();
  }, []);

  return (
    <TimeWindowContext.Provider value={{
      start,
      end,
      days,
      model,
      setStart: setStartAndEnd,
      isValidDate,
      disabledDate,
      findNearestStartDate,
    }}
    >
      {children}
    </TimeWindowContext.Provider>
  );
};

TimeWindowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TimeWindowProvider;

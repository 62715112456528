import React, { useState } from 'react';
import PropTypes from 'prop-types';
import YoutrackIssuesSelectedContext from './YoutrackIssuesSelectedContext';

const YoutrackIssuesSelectedProvider = ({ children }) => {
  const [selected, setSelected] = useState([]);

  const update = (record) => {
    let newSelected = [];
    let index = selected.findIndex((element) => element.id === record.id);
    if (index !== -1) {
      newSelected = [...selected];
      newSelected[index] = record;
    }

    if (index === -1) {
      newSelected = selected.map((element) => {
        if (element.children && element.children.length) {
          index = element.children.findIndex((child) => child.id === record.id);

          if (index !== -1) {
            const newElement = { ...element };
            newElement.children[index] = record;
            return newElement;
          }
        }

        return element;
      });
    }
    setSelected(newSelected);
  };

  const reset = () => {
    setSelected([]);
  };

  return (
    <YoutrackIssuesSelectedContext.Provider value={{
      selected, setSelected, update, reset,
    }}
    >
      {children}
    </YoutrackIssuesSelectedContext.Provider>
  );
};

YoutrackIssuesSelectedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default YoutrackIssuesSelectedProvider;

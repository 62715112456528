import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Affix,
  Badge, Button, Form, Input, notification, Popconfirm, Table, Tooltip,
} from 'antd';
import {
  EditOutlined, MessageFilled, RedoOutlined,
  SaveOutlined, UndoOutlined, VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProjectContext from '../../Context/Project/ProjectContext';
import YoutrackIssuesContext from '../../Context/Youtrack/Issues/YoutrackIssuesContext';
import IssuesStateMultipleSelect from './IssuesStateMultipleSelect';
import IssuesStateOptions from './IssuesStateOptions';
import IssuesTableSummary from './IssuesTableSummary';
import { processDataSource, getSelectedRowKeys } from '../../Helpers/YouTrack/Issue';
import ConnectorContext from '../../Context/Connector/ConnectorContext';
import { notify } from '../../Helpers/Error';
import IssuesToCsv from './IssuesToCsv';
import EditableCell from '../EditableCell/EditableCell';
import YoutrackIssuesSelectedContext from '../../Context/Youtrack/Issues/Selected/YoutrackIssuesSelectedContext';
import IssuesBillByFeatureCheckbox from './IssuesBillByFeatureCheckbox';
import YoutrackIssuesTypeContext from '../../Context/Youtrack/Issues/Type/YoutrackIssuesTypeContext';
import YoutrackContext from '../../Context/Youtrack/YoutrackContext';

const IssuesTable = ({ onClickComment }) => {
  const { id } = useParams();
  const { dataSource: projects } = useContext(ProjectContext);
  const { reload } = useContext(YoutrackContext);
  const {
    dataSource, loading: youtrackLoading, state, setState, setQuery, update,
  } = useContext(YoutrackIssuesContext);
  const { dataSource: connectors } = useContext(ConnectorContext);
  const {
    selected, setSelected,
    update: updateIssuesSelected,
  } = useContext(YoutrackIssuesSelectedContext);
  const { type } = useContext(YoutrackIssuesTypeContext);
  const project = projects.find((item) => item.youtrack === id);
  const [editing, setEditing] = useState(false);
  const [customPrices, setCustomPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processedDataSource, setProcessedDataSource] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [form] = Form.useForm();
  const billButton = useRef(null);

  useEffect(() => {
    setQuery('');
    setState(['Fixed', 'Approved']);
  }, [setState, setQuery]);

  useEffect(() => {
    setProcessedDataSource(processDataSource(dataSource, project, customPrices, state));
  }, [dataSource, project, customPrices, state]);

  const isEditing = (record) => record.id === editing.id;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditing(record);
  };

  const cancel = () => {
    setEditing(false);
  };

  const save = async (record) => {
    try {
      const val = {
        summary: record.summary,
        customFields: [
          {
            value: {
              name: IssuesStateOptions.find(({ value }) => value === record.state).label,
            },
            name: 'State',
            $type: 'StateIssueCustomField',
          },
        ],
      };
      update(record, val);
      setEditing(false);

      const newRecord = { id: record.id, rate: record.rate };
      setCustomPrices((prevState) => {
        const prevRecord = prevState.find((element) => element.id === record.id);
        if (prevRecord) {
          return prevState.map((element) => {
            if (element.id === record.id) {
              return newRecord;
            }
            return element;
          });
        }
        return [...prevState, newRecord];
      });
      updateIssuesSelected(record);
    } catch (err) {
      notify(err, 'Error updating record');
      setEditing(false);
    }
  };

  const renderFieldWithPresentation = (field) => {
    const { presentation, minutes } = field;
    if (presentation || minutes) {
      return (
        <>
          { presentation }
          <br />
          <small>{ `(${minutes}m)` }</small>
        </>
      );
    }

    return null;
  };

  const commentsRow = (text, row) => {
    if (text) {
      const { isReadable } = row;
      return (
        <Badge count={text}>
          <Button
            shape="circle"
            size="small"
            onClick={() => onClickComment(row)}
            id={isReadable}
          >
            <MessageFilled style={{ fontSize: '24px' }} />
          </Button>
        </Badge>
      );
    }

    return null;
  };

  const markTasksAsBilled = async (record) => {
    if (!record.children || !record.children.length) {
      await save({
        ...record,
        state: '{Closed (Billed)}',
      });
    }
  };

  const getIssues = () => {
    if (type !== 'feature') {
      return selected;
    }

    const issues = [];
    processedDataSource.forEach((record) => {
      if (record.children && record.children.length) {
        record.children.forEach((children) => {
          const selectedChildren = selected.find((element) => element.id === children.id);

          if (selectedChildren) {
            const issueIndex = issues.findIndex((issue) => issue.id === record.id);
            if (issueIndex !== -1) {
              issues[issueIndex].price += selectedChildren.price;
            }

            if (issueIndex === -1) {
              issues.push({
                ...record,
                price: selectedChildren.price,
              });
            }
          }
        });
      }
    });

    return issues;
  };

  const onFinish = async () => {
    getIssues();

    setLoading(true);

    const params = {
      authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      connector: connectors.find((conn) => conn.service === 'xero'),
      project,
      issues: getIssues(),
    };

    const path = '/api/xero/invoices';
    axios.post(process.env.REACT_APP_TASKHUB_PROXY_HOST + path, params)
      .then(() => {
        selected.forEach((record) => markTasksAsBilled(record));
        setLoading(false);
        notification.success({ message: 'Invoice created on Xero' });
      })
      .catch((err) => notify(err, 'Error on taskhub-proxies', setLoading));
  };

  const removeCustomPrice = (row) => {
    setCustomPrices((prevState) => {
      const newState = [...prevState];
      newState.splice(
        newState.findIndex((element) => element.id === row.id), 1,
      );
      return newState;
    });
  };

  const operations = (row) => {
    const editable = isEditing(row);

    if (editable) {
      return (
        <div className="table-ghost-wrapper">
          <Button
            type="primary"
            ghost
            shape="circle"
            onClick={() => form.validateFields().then(((valid) => save({ ...row, ...valid })))}
          >
            <SaveOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to cancel?"
            okType="ghost"
            icon={<UndoOutlined style={{ color: 'grey' }} />}
            onConfirm={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="ghost" shape="circle">
              <UndoOutlined />
            </Button>
          </Popconfirm>
        </div>
      );
    }
    const customPrice = customPrices.find((element) => element.id === row.id);

    return (
      <div className="table-ghost-wrapper">
        <Button type="primary" ghost shape="circle" onClick={() => edit(row)}>
          <EditOutlined />
        </Button>
        { customPrice
        && (
        <Tooltip title="Remove custom price">
          <Button type="secondary" shape="circle" onClick={() => removeCustomPrice(customPrice)}>
            <UndoOutlined />
          </Button>
        </Tooltip>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: 'Parent',
      dataIndex: 'parent_id',
      key: 'parent_id',
      sorter: (a, b) => a.parent_id.localeCompare(b.parent_id),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      editable: true,
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      editable: true,
      sorter: (a, b) => a.summary.localeCompare(b.summary),
    },
    {
      title: 'Estimate',
      dataIndex: 'estimation',
      key: 'estimation',
      sorter: (a, b) => a.estimation.minutes - b.estimation.minutes,
      render: renderFieldWithPresentation,
    },
    {
      title: 'Timed',
      dataIndex: 'spentTime',
      key: 'spentTime',
      sorter: (a, b) => a.spentTime.minutes - b.spentTime.minutes,
      render: renderFieldWithPresentation,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      editable: true,
      sorter: (a, b) => a.rate - b.rate,
      render: (text) => `$${text}`,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text) => `$${text}`,
    },
    {
      title: 'Comments',
      dataIndex: 'commentsCount',
      key: 'commentsCount',
      sorter: (a, b) => a.commentsCount - b.commentsCount,
      render: commentsRow,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, row) => operations(row),
    }];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const selectedRowKeys = getSelectedRowKeys(processedDataSource, selected);

  const selectRow = (text, selection) => {
    setSelected((prevSelected) => {
      const toAttachOnResult = prevSelected.filter(
        (prevRow) => !selectedRowKeys.find((srk) => srk === prevRow.id),
      );
      return [...toAttachOnResult, ...selection];
    });
  };

  return (
    <>
      <Affix onChange={setScrolled}>
        {scrolled && (
        <div className="ant-back-top">
          <div className="">
            <div className="ant-back-top-content">
              <div className="ant-back-top-icon">
                <VerticalAlignBottomOutlined onClick={() => billButton.current.scrollIntoView({ behavior: 'smooth' })} />
              </div>
            </div>
          </div>
        </div>
        )}
      </Affix>

      <Form.Item>
        <Input.Search
          placeholder="Search"
          enterButton
          onSearch={setQuery}
        />
      </Form.Item>

      <Form layout="inline" style={{ margin: '0 0 15px 0' }}>
        <IssuesStateMultipleSelect value={state} onChange={setState} />
        <IssuesBillByFeatureCheckbox />
        <Button type="primary" onClick={() => reload()}>
          Reload
          <RedoOutlined />
        </Button>

      </Form>

      <Form
        form={form}
        onFinish={onFinish}
      >
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={processedDataSource}
          columns={mergedColumns}
          loading={loading}
          rowKey="id"
          pagination={false}
          size="small"
          summary={(data) => <IssuesTableSummary data={data} selected={selected} />}
          rowSelection={{
            type: 'checkbox',
            checkStrictly: false,
            selectedRowKeys,
            onChange: selectRow,
          }}
        />
        <Popconfirm
          title="Are you sure to bill the selected tasks on xero?"
          onConfirm={form.submit}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{ margin: 15 }}
            type="primary"
            disabled={!selected.length}
            loading={loading || youtrackLoading}
            ref={billButton}
          >
            Bill
          </Button>
        </Popconfirm>

        <span style={{ marginLeft: 8 }}>
          {selected.length ? `Selected ${selected.length} items` : ''}
        </span>
      </Form>
      <IssuesToCsv data={processedDataSource} />

    </>
  );
};

IssuesTable.defaultProps = {
  onClickComment: () => {},
};

IssuesTable.propTypes = {
  onClickComment: PropTypes.func,
};

export default IssuesTable;

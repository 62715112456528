import { API, graphqlOperation } from 'aws-amplify';
import { notification } from 'antd';
import * as mutations from '../graphql/mutations';
import { notify } from './Error';

export const action = async (operation, variables, setLoading = () => {}) => {
  setLoading(true);
  const response = await API.graphql(graphqlOperation(operation, variables));
  setLoading(false);
  return response;
};

export const onFinish = (
  modelName,
  fieldsValue,
  afterSubmit = () => {},
  setLoading = () => {},
  setError = () => {},
) => {
  const casedModelName = modelName.charAt(0).toUpperCase() + modelName.slice(1);
  const { id } = fieldsValue;
  const key = id ? `update${casedModelName}` : `create${casedModelName}`;

  action(mutations[key], { input: fieldsValue }, setLoading).then((response) => {
    notification.success({ message: `Record ${id ? 'updated' : 'created'}` });
    afterSubmit(response);
  }).catch((error) => notify(error, `Error ${id ? 'updating' : 'creating'} record`, setLoading, setError));
};

export const onChange = ({ target: { name, value } }, setForm) => {
  setForm((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

export const Form = {
  action,
  onFinish,
  onChange,
};

export default Form;

import { createContext } from 'react';

export const defaultValue = {
  loaded: false,
  dataSource: [],
};

const FlextimeContext = createContext(defaultValue);

export default FlextimeContext;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, Popconfirm, Table,
} from 'antd';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import ProjectButton from './ProjectButton';
import ProjectContext from '../../Context/Project/ProjectContext';
import YoutrackContext from '../../Context/Youtrack/YoutrackContext';

const ProjectsTable = ({ onClickEdit }) => {
  const { dataSource, loading, deleteModel } = useContext(ProjectContext);
  const { dataSource: youtrackDataSource, loading: youtrackLoading } = useContext(YoutrackContext);
  const [filter, setFilter] = useState(null);

  const renderOperations = (id, row) => {
    const ytproject = youtrackDataSource.find(({ shortName }) => shortName === row.youtrack);

    return (
      <>
        <div className="table-ghost-wrapper">
          { ytproject ? <ProjectButton shortName={ytproject.shortName} /> : null }
          <Button type="primary" ghost shape="circle" onClick={() => onClickEdit(row)}>
            <EditOutlined />
          </Button>

          <Popconfirm
            title="Are you sure to delete this record?"
            okType="danger"
            icon={<WarningOutlined style={{ color: 'red' }} />}
            onConfirm={() => deleteModel(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" ghost shape="circle" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      </>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'youtrack',
      key: 'youtrack',
      sorter: (a, b) => a.youtrack.localeCompare(b.youtrack),
      render: (text) => {
        const project = youtrackDataSource.find(({ shortName }) => shortName === text);
        return project ? project.name : text;
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      sorter: (a, b) => a.rate.localeCompare(b.rate),
    },
    {
      title: 'Operation',
      dataIndex: 'id',
      key: 'operation',
      render: renderOperations,
    },
  ];

  const onSearch = (value) => {
    const filterTable = dataSource.filter((o) => Object.keys(o).some((k) => String(o[k])
      .toLowerCase()
      .includes(value.toLowerCase())));
    setFilter(filterTable);
  };

  return (
    <>
      <Input.Search
        style={{ margin: '0 0 15px 0' }}
        placeholder="Search"
        enterButton
        onSearch={onSearch}
      />
      <Table
        style={{ overflow: 'auto' }}
        dataSource={filter || dataSource}
        columns={columns}
        size="middle"
        pagination={false}
        loading={loading || youtrackLoading}
        rowKey="id"
      />
    </>
  );
};

ProjectsTable.defaultProps = {
  onClickEdit: () => {},
};

ProjectsTable.propTypes = {
  onClickEdit: PropTypes.func,
};

export default ProjectsTable;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConnector = /* GraphQL */ `
  mutation CreateConnector(
    $input: CreateConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    createConnector(input: $input, condition: $condition) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const updateConnector = /* GraphQL */ `
  mutation UpdateConnector(
    $input: UpdateConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    updateConnector(input: $input, condition: $condition) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const deleteConnector = /* GraphQL */ `
  mutation DeleteConnector(
    $input: DeleteConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    deleteConnector(input: $input, condition: $condition) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const createFlextime = /* GraphQL */ `
  mutation CreateFlextime(
    $input: CreateFlextimeInput!
    $condition: ModelFlextimeConditionInput
  ) {
    createFlextime(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateFlextime = /* GraphQL */ `
  mutation UpdateFlextime(
    $input: UpdateFlextimeInput!
    $condition: ModelFlextimeConditionInput
  ) {
    updateFlextime(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteFlextime = /* GraphQL */ `
  mutation DeleteFlextime(
    $input: DeleteFlextimeInput!
    $condition: ModelFlextimeConditionInput
  ) {
    deleteFlextime(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createTimeEntry = /* GraphQL */ `
  mutation CreateTimeEntry(
    $input: CreateTimeEntryInput!
    $condition: ModelTimeEntryConditionInput
  ) {
    createTimeEntry(input: $input, condition: $condition) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeEntry = /* GraphQL */ `
  mutation UpdateTimeEntry(
    $input: UpdateTimeEntryInput!
    $condition: ModelTimeEntryConditionInput
  ) {
    updateTimeEntry(input: $input, condition: $condition) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeEntry = /* GraphQL */ `
  mutation DeleteTimeEntry(
    $input: DeleteTimeEntryInput!
    $condition: ModelTimeEntryConditionInput
  ) {
    deleteTimeEntry(input: $input, condition: $condition) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;

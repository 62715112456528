import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, Skeleton } from 'antd';
import moment, { isMoment } from 'moment';
import TimeWindowContext from '../../Context/TimeWindow/TimeWindowContext';

const TimeWindowPicker = () => {
  const {
    days,
    model,
    disabledDate,
    findNearestStartDate,
    setStart,
  } = useContext(TimeWindowContext);
  const [defaultValue, setDefaultValue] = useState(false);

  useEffect(() => {
    if (Object.keys(model).length && days && !defaultValue) {
      const date = findNearestStartDate(moment());
      setDefaultValue(date.startOf('day'));
      setStart(date);
    }
  }, [model, defaultValue, days, findNearestStartDate, setStart]);

  if (!isMoment(defaultValue)) {
    return <Skeleton.Input active />;
  }

  return (
    <DatePicker
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      disabledDate={disabledDate}
      showToday={false}
      onChange={setStart}
    />
  );
};

export default TimeWindowPicker;

export const getItems = (response, key) => {
  let { value } = response;

  if (!value) {
    value = response;
  }

  const { data } = value;
  if (data && data[key]) {
    const { [key]: collection } = data;
    if (collection) {
      const { items: itemsCollection } = collection;
      if (itemsCollection) {
        return itemsCollection;
      }

      return collection;
    }
  }

  return [];
};

export const getEnumValues = ({ data }) => {
  if (data) {
    const { enum: enumData } = data;
    if (enumData) {
      const { enumValues } = enumData;
      if (enumValues && enumValues.length) {
        return enumValues;
      }
    }
  }

  return [];
};

export const addItemIfNotExists = (response, key, collection, itemKey = 'id') => {
  const item = getItems(response, key);
  if (!collection.find((current) => current[itemKey] === item[itemKey])) {
    const newCollection = [...collection];
    newCollection.unshift(item);
    return newCollection;
  }

  return collection;
};

export const findAndUpdate = (response, key, collection, itemKey = 'id') => {
  const item = getItems(response, key);
  const newCollection = [...collection];

  newCollection.forEach((element, index) => {
    if (element[itemKey] === item[itemKey]) {
      newCollection[index] = item;
    }
  });

  return newCollection;
};

const Response = {
  items: getItems,
  enumValues: getEnumValues,
  addItemIfNotExists,
  findAndUpdate,
};

export default Response;

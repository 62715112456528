import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';
import { addItemIfNotExists, findAndUpdate } from './Response';

export const subscribe = (owner = {}, key = '', callback = () => {}) => API.graphql(
  graphqlOperation(
    subscriptions[key], owner,
  ),
).subscribe(callback);

export const onCreateModel = (key, params, setDataSource, setLoading) => subscribe(params, key, {
  next: (response) => {
    setLoading(true);
    setDataSource((prevDataSource) => addItemIfNotExists(response, key, prevDataSource));
    setLoading(false);
  },
});

export const onUpdateModel = (key, params, setDataSource, setLoading) => subscribe(params, key, {
  next: (response) => {
    setLoading(true);
    setDataSource((prevDataSource) => findAndUpdate(response, key, prevDataSource));
    setLoading(false);
  },
});

const Subscription = {
  subscribe,
  onUpdateModel,
};

export default Subscription;

import { parse } from 'json2csv';

export const dataToCSV = (data, headers) => {
  let csvData = [];

  if (!headers || (headers && !headers.length)) {
    return [];
  }

  const keys = headers.map(({ key }) => key);

  data.forEach((item) => {
    const issue = { ...item };
    if (issue.children && issue.children.length) {
      csvData = csvData.concat(dataToCSV(issue.children));
      delete issue.children;
    }

    Object.keys(issue).forEach(((key) => {
      if (keys.includes(key)) {
        const header = headers.find((element) => element.key === key);
        issue[header.label] = issue[key];
      }

      delete issue[key];
    }));

    csvData.push(issue);
  });
  return csvData.length ? parse(csvData) : [];
};

export const CSV = {
  dataToCSV,
};

export default CSV;

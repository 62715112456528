import React from 'react';
import PropTypes from 'prop-types';
import TimeEntryProvider from './TimeEntry/TimeEntryProvider';
import SidebarProvider from './Sidebar/SidebarProvider';
import UserProvider from './User/UserProvider';
import TimeWindowProvider from './TimeWindow/TimeWindowProvider';
import FlextimeProvider from './Flextime/FlextimeProvider';
import ConnectorProvider from './Connector/ConnectorProvider';
import ProjectProvider from './Project/ProjectProvider';
import YoutrackProvider from './Youtrack/YoutrackProvider';
import YoutrackIssuesProvider from './Youtrack/Issues/YoutrackIssuesProvider';
import XeroProvider from './Xero/XeroProvider';
import YoutrackIssuesTypeProvider from './Youtrack/Issues/Type/YoutrackIssuesTypeProvider';

const GlobalState = ({ children }) => (
  <UserProvider>
    <SidebarProvider>
      <TimeWindowProvider>
        <FlextimeProvider>
          <TimeEntryProvider>
            <ConnectorProvider>
              <ProjectProvider>
                <XeroProvider>
                  <YoutrackIssuesTypeProvider>
                    <YoutrackProvider>
                      <YoutrackIssuesProvider>
                        {children}
                      </YoutrackIssuesProvider>
                    </YoutrackProvider>
                  </YoutrackIssuesTypeProvider>
                </XeroProvider>
              </ProjectProvider>
            </ConnectorProvider>
          </TimeEntryProvider>
        </FlextimeProvider>
      </TimeWindowProvider>
    </SidebarProvider>
  </UserProvider>
);

GlobalState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalState;

import React, { useState } from 'react';
import {
  Button, PageHeader, Typography,
} from 'antd';
import ProjectsTable from '../Components/Project/ProjectsTable';
import ProjectSettingsDrawer from '../Components/Project/ProjectDrawer';
import { edit } from '../Helpers/Card';

const { Title } = Typography;

const Projects = () => {
  const initialValues = { rate: 150, currency: 'NZD' };
  const [project, setProject] = useState(initialValues);
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <PageHeader
        style={{ width: '100%' }}
        bordered={false}
        title={<Title>Projects</Title>}
        extra={(
          <Button
            type="primary"
            onClick={() => setDrawerVisible(true)}
          >
            Create
          </Button>
        )}
        bodyStyle={{ padding: 0 }}
      >
        <ProjectsTable onClickEdit={(event) => edit(event, setProject, setDrawerVisible)} />
        <ProjectSettingsDrawer
          initialValues={project}
          visible={drawerVisible}
          onClose={() => {
            setDrawerVisible(false);
            setProject(initialValues);
          }}
        />
      </PageHeader>
    </>
  );
};

export default Projects;

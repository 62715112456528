import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as Logo } from '../../Assets/youtrack/logo.svg';
import ConnectorContext from '../../Context/Connector/ConnectorContext';

const YoutrackConnectButton = () => {
  const { dataSource } = useContext(ConnectorContext);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const connector = dataSource.find((item) => item.service === 'youtrack');
    setConnected(!!connector);
  }, [dataSource]);

  const disconnect = () => {
    setConnected(false);
  };

  const connect = () => {
    const width = 600;
    const height = 860;
    const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

    const url = new URL(process.env.REACT_APP_YOUTRACK_APP_AUTH_HOST);
    const params = {
      response_type: 'code',
      client_id: process.env.REACT_APP_YOUTRACK_APP_CLIENT_ID,
      scope: process.env.REACT_APP_YOUTRACK_APP_SCOPE,
      redirect_uri: process.env.REACT_APP_YOUTRACK_APP_REDIRECT,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    window.open(url.toString(), 'popup', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);
  };

  const onClick = () => {
    if (connected) {
      return disconnect();
    }

    return connect();
  };

  return (
    <div role="presentation" onClick={onClick}>
      <Button icon={<Icon component={Logo} size="large" />}>
        {!connected ? 'Connect to YouTrack' : 'Disconnect from YouTrack'}
      </Button>
    </div>
  );
};

export default YoutrackConnectButton;

import React, { useContext } from 'react';
import { Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import { fromMinutes } from '../../Helpers/Duration';
import YoutrackIssuesTypeContext from '../../Context/Youtrack/Issues/Type/YoutrackIssuesTypeContext';

const { Text } = Typography;

const IssuesTableSummary = ({ data, selected }) => {
  const { type } = useContext(YoutrackIssuesTypeContext);

  const getResults = (elements) => {
    let total = 0;
    let estimation = 0;
    let spentTime = 0;

    elements.forEach((element) => {
      if (type !== element.type.toLowerCase()) {
        return;
      }
      total += Number(element.price);
      if (element.estimation.minutes) {
        estimation += Number(element.estimation.minutes);
      }
      spentTime += Number(element.spentTime.minutes);
    });

    return {
      total,
      estimation,
      spentTime,
    };
  };

  const getSummary = (elements) => {
    const { estimation, spentTime, total } = getResults(elements);
    return (
      <>
        <Table.Summary.Cell>
          <>
            {fromMinutes(estimation)}
            <br />
            <small>{ `(${estimation}m)` }</small>
          </>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={2}>
          <>
            {fromMinutes(spentTime)}
            <br />
            <small>{ `(${spentTime}m)` }</small>
          </>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Text strong>{`$${total}`}</Text>
        </Table.Summary.Cell>
      </>
    );
  };

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6}>
          <Text strong>
            Sub-Total
            &nbsp;
            <small>(excl. GST)</small>
          </Text>
        </Table.Summary.Cell>
        {getSummary(data)}
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6}>
          <Text strong>
            Selected Total
            &nbsp;
            <small>(excl. GST)</small>
          </Text>
        </Table.Summary.Cell>
        {getSummary(selected)}
      </Table.Summary.Row>
    </>
  );
};

IssuesTableSummary.defaultProps = {
  selected: [],
  data: [],
};

IssuesTableSummary.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),

};

export default IssuesTableSummary;

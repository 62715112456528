import React, { useState } from 'react';
import {
  Button, PageHeader, Drawer, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { edit } from '../../Helpers/Card';
import ConnectorForm from './ConnectorForm';
import ConnectorList from './ConnectorList';
import XeroConnectButton from '../Xero/XeroConnectButton';
import YoutrackConnectButton from '../Youtrack/YoutrackConnectButton';

const { Title } = Typography;

const ConnectorCard = ({ title }) => {
  const [model, setModel] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { id } = model;

  return (
    <PageHeader
      style={{ width: '100%' }}
      bordered={false}
      title={(<Title>{title}</Title>)}
      extra={(
        <Button
          type="primary"
          onClick={() => {
            setModel({});
            setDrawerVisible(true);
          }}
        >
          Create
        </Button>
      )}
      bodyStyle={{ padding: 0 }}
    >
      <YoutrackConnectButton />
      <XeroConnectButton />

      <ConnectorList
        onClickEdit={(event) => edit(event, setModel, setDrawerVisible)}
      />
      <Drawer
        title={`${id ? 'Edit' : 'Create a new'} record`}
        placement="right"
        width={isMobile ? '80%' : '40%'}
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        destroyOnClose
      >
        <ConnectorForm afterSubmit={() => setDrawerVisible(false)} model={model} />
      </Drawer>
    </PageHeader>
  );
};

ConnectorCard.defaultProps = {
  title: 'Connectors',
};

ConnectorCard.propTypes = {
  title: PropTypes.string,
};

export default ConnectorCard;

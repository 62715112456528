import React, { useContext, useEffect, useState } from 'react';
import { GroupedColumnChart } from '@opd/g2plot-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FlextimeContext from '../../Context/Flextime/FlextimeContext';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import {
  FLEXTIME_COLOR, SICK_COLOR, OVERTIME_COLOR, LEAVETIME_COLOR, UNPAID_COLOR,
} from '../../Constants/Colors';
import { fromDecimalHours } from '../../Helpers/Duration';

const ActivityBarChart = ({ period: { start, end } }) => {
  const { dataSource: flextimes } = useContext(FlextimeContext);
  const { dataSource: timeentries } = useContext(TimeEntryContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const sumFlextimeHoursOnADate = (date) => {
      const result = flextimes.reduce((accumulator, { createdAt, updatedAt }) => {
        const currentDate = moment(createdAt);
        if (currentDate.startOf('day').isSame(date.startOf('day'))) {
          const timeDiff = moment(updatedAt).diff(moment(createdAt));
          return accumulator + moment.duration(timeDiff).asHours();
        }

        return accumulator;
      }, 0);

      return Math.round(result * 100) / 100;
    };

    const sumTimeentryHoursOnADate = (date, type) => {
      const result = timeentries.reduce(
        (accumulator, {
          date: timeentryDate, duration, type: timeentryType, approved,
        }) => {
          if (moment(timeentryDate).isSame(date.startOf('day')) && type === timeentryType && approved) {
            return accumulator + moment.duration(duration, 'seconds').asHours();
          }
          return accumulator;
        }, 0,
      );

      return Math.round(result * 100) / 100;
    };

    const addData = (collection, type, date, hours, validateHours = true) => {
      if (hours || !validateHours) {
        collection.push({
          Type: type,
          Day: date.format('ddd DD'),
          Hours: hours,
        });
      }
    };

    const collection = [];
    const days = end.diff(start, 'days');
    for (let i = 0; i <= days; i += 1) {
      const date = start.clone().add(i, 'days');

      addData(
        collection,
        'Actual',
        date,
        sumFlextimeHoursOnADate(date),
        false,
      );

      addData(
        collection,
        'Sick',
        date,
        sumTimeentryHoursOnADate(date, 'SickTime'),
      );

      addData(
        collection,
        'Overtime',
        date,
        sumTimeentryHoursOnADate(date, 'OverTime'),
      );

      addData(
        collection,
        'Leave',
        date,
        sumTimeentryHoursOnADate(date, 'LeaveTime'),
      );

      addData(
        collection,
        'Unpaid',
        date,
        sumTimeentryHoursOnADate(date, 'Unpaid'),
      );
    }

    setData(collection);
  }, [start, end, flextimes, timeentries]);

  return (
    <GroupedColumnChart
      forceFit
      data={data}
      xField="Day"
      yField="Hours"
      label={{ visible: false }}
      groupField="Type"
      legend={{ position: 'bottom-left' }}
      color={(group) => {
        switch (group) {
          case 'Sick':
            return SICK_COLOR;
          case 'Overtime':
            return OVERTIME_COLOR;
          case 'Leave':
            return LEAVETIME_COLOR;
          case 'Unpaid':
            return UNPAID_COLOR;
          default:
            return FLEXTIME_COLOR;
        }
      }}
      xAxis={{ title: false }}
      tooltip={{
        formatter: (date, time, type) => ({ name: type, value: `${time ? `${fromDecimalHours(time)} |` : ''} ${time}` }),
      }}
    />
  );
};

ActivityBarChart.propTypes = {
  period: PropTypes.shape({
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
  }),
};

ActivityBarChart.defaultProps = {
  period: {
    start: moment(),
    end: moment(),
  },
};

export default ActivityBarChart;

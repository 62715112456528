import { createContext } from 'react';

export const defaultValue = {
  days: 0,
  model: {},
  isValidDate: () => {},
  setStart: () => {},
  disabledDate: () => {},
  findNearestStartDate: () => {},
};

const TimeWindowContext = createContext(defaultValue);

export default TimeWindowContext;

import React, { useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeEntryContext from './TimeEntryContext';
import { getEnumValues } from '../../Helpers/GraphQL/Response';
import { listEnumValues, listModels } from '../../Helpers/GraphQL/Query';
import { onCreateModel, onUpdateModel } from '../../Helpers/GraphQL/Subscription';
import SidebarContext from '../Sidebar/SidebarContext';
import { deleteRecord } from '../../Helpers/GraphQL/Mutation';
import * as mutations from '../../graphql/mutations';
import TimeWindowContext from '../TimeWindow/TimeWindowContext';
import { action } from '../../Helpers/Form';

const TimeEntryProvider = ({ children }) => {
  const { selected } = useContext(SidebarContext);
  const { start, end } = useContext(TimeWindowContext);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [previousLoad, setPreviousLoad] = useState({});

  const deleteModel = (id) => {
    const key = 'deleteTimeEntry';

    deleteRecord(key, id).then(() => {
      setDataSource((prevDataSource) => prevDataSource.filter((record) => record.id !== id));
    });
  };

  const setApproved = async (id, approved) => {
    await action(mutations.updateTimeEntry, { input: { id, approved } }, setLoading);
  };

  useEffect(() => {
    const listTimeOptions = () => API.graphql(graphqlOperation(listEnumValues, { enum: 'TimeOptions' })).then((response) => {
      setTimeOptions(getEnumValues(response));
    });

    const { selected: prevSelected, start: prevStart } = previousLoad;

    if (!Object.keys(previousLoad).length) {
      listTimeOptions();
    }

    if (selected !== prevSelected) {
      const params = { owner: selected };
      onCreateModel('onCreateTimeEntry', params, setDataSource, setLoading);
      onUpdateModel('onUpdateTimeEntry', params, setDataSource, setLoading);
      setPreviousLoad((prevPreviousLoad) => ({ ...prevPreviousLoad, selected }));
    }

    if (selected !== prevSelected || start !== prevStart) {
      const sort = (a, b) => moment(b.start).unix() - moment(a.start).unix();
      listModels('listTimeEntrys', selected, start, end, sort, setDataSource, setLoading, 'date');
      setPreviousLoad((prevPreviousLoad) => ({ ...prevPreviousLoad, start }));
    }
  }, [selected, start, end, previousLoad]);

  return (
    <TimeEntryContext.Provider value={{
      dataSource,
      loading,
      timeOptions,
      deleteModel,
      setApproved,
    }}
    >
      {children}
    </TimeEntryContext.Provider>
  );
};

TimeEntryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TimeEntryProvider;

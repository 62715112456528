import React, { useEffect, useState } from 'react';
import {
  Select, Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import bugherdService from '../../Services/Bugherd.service';

const { Option } = Select;

const BugheardSelect = ({ selected, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setLoading(true);
    bugherdService.projects(123).then((data) => {
      const { projects } = data;
      if (projects) {
        projects.sort();
        projects.sort((a, b) => a.name > b.name);
      }

      setDataSource(data.projects);
      setLoading(false);
    });
  }, []);

  return (
    <>
      { loading ? <Skeleton.Input active /> : (
        <Select
          showSearch
          placeholder="Select a project"
          onChange={onChange}
          value={selected ? parseInt(selected, 10) : null}
          filterOption={(
            input,
            {
              props: { children },
            },
          ) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            dataSource.map(
              ({ name, id }) => (
                <Option
                  key={id}
                  value={id}
                >
                  {name}
                </Option>
              ),
            )
          }
        </Select>
      )}
    </>

  );
};

BugheardSelect.defaultProps = {
  onChange: () => {},
  selected: null,
};

BugheardSelect.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export default BugheardSelect;

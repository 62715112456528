import React, { useContext, useState } from 'react';
import {
  Form, Button, notification, Input, DatePicker, Radio, Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import * as mutations from '../../graphql/mutations';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import { toSeconds } from '../../Helpers/Duration';
import UserContext from '../../Context/User/UserContext';
import { action, onChange } from '../../Helpers/Form';

const TimeEntryForm = ({ model, afterSubmit }) => {
  const { isAdmin } = useContext(UserContext);
  const { timeOptions } = useContext(TimeEntryContext);
  const [form, setForm] = useState(model);
  const [loading, setLoading] = useState(false);

  const onFinish = (fieldsValue) => {
    const { id, date, duration } = fieldsValue;
    const key = id ? 'updateTimeEntry' : 'createTimeEntry';

    const values = {
      ...fieldsValue,
      date: date.format('YYYY-MM-DD'),
      duration: toSeconds(duration),
      approved: form.approved,
    };

    action(mutations[key], { input: { ...values } }, setLoading).then((response) => {
      notification.success({ message: `Record ${id ? 'updated' : 'created'}` });
      afterSubmit(response);
    });
  };

  return (
    <Form
      name="TimeEntry-form"
      onFinish={onFinish}
      initialValues={model}
      labelCol={{ span: 5 }}
    >
      <Form.Item name="id" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="owner" label="Owner" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="date" label="Date" rules={[{ required: true }]}>
        <DatePicker
          onChange={(value) => onChange({ target: { name: 'date', value } }, setForm)}
        />
      </Form.Item>

      <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a type' }]}>
        <Radio.Group
          onChange={(event) => onChange(event, setForm)}
          value={form.type}
        >
          { timeOptions.map(({ name }) => <Radio key={name} value={name}>{name}</Radio>) }
        </Radio.Group>
      </Form.Item>

      <Form.Item name="duration" label="Time" rules={[{ required: true }]}>
        <Input placeholder="?" style={{ width: 150 }} />
      </Form.Item>

      <Form.Item name="approved" label="Approved" rules={[{ required: true }]} style={{ display: isAdmin || 'none' }}>
        <Checkbox
          onChange={({ target: { checked } }) => onChange({ target: { name: 'approved', value: !!checked } }, setForm)}
          checked={form.approved}
        >
          Approved
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

TimeEntryForm.defaultProps = {
  afterSubmit: () => {},
};

TimeEntryForm.propTypes = {
  afterSubmit: PropTypes.func,
  model: PropTypes.shape({}).isRequired,
};

export default TimeEntryForm;

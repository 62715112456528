import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  FieldTimeOutlined, ScheduleFilled, IdcardFilled, ApiFilled,
} from '@ant-design/icons';
import UserContext from '../../Context/User/UserContext';
import UserMenu from '../User/UserMenu';

const { Header } = Layout;

const Topbar = () => {
  const { pathname } = useLocation();
  const { isAdmin, isManager } = useContext(UserContext);

  const paths = [
    {
      name: '',
      key: 'Home',
    },
    {
      name: 'project',
      key: 'projects',
    },
    {
      name: 'projects',
      key: 'projects',
    },
    {
      name: 'users',
      key: 'users',
    },
    {
      name: 'integrations',
      key: 'integrations',
    },
  ];

  let selectedKeys = [];
  const pathResult = paths.find(({ name }) => name === pathname.split('/')[1]);
  if (pathResult) {
    selectedKeys = [pathResult.key];
  }

  return (
    <Header>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ float: 'right' }}
        defaultSelectedKeys={['Home']}
        selectedKeys={selectedKeys}
      >
        <Menu.Item key="Home">
          <Link to="/">
            <FieldTimeOutlined />
            <span className="nav-word">Time Log</span>
          </Link>
        </Menu.Item>
        {
          (isAdmin || isManager) && (
            <Menu.Item key="projects">
              <Link to="/projects">
                <ScheduleFilled />
                <span className="nav-word">Projects</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          isAdmin && (
            <Menu.Item key="users">
              <Link to="/users">
                <IdcardFilled />
                <span className="nav-word">Users</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          (isAdmin || isManager) && (
            <Menu.Item key="integrations">
              <Link to="/integrations">
                <ApiFilled />
                <span className="nav-word">Integrations</span>
              </Link>
            </Menu.Item>
          )
        }
        <UserMenu />
      </Menu>
    </Header>
  );
};

export default Topbar;

import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import {
  FLEXTIME_COLOR, LEAVETIME_COLOR, OVERTIME_COLOR, SICK_COLOR, UNPAID_COLOR,
} from '../../Constants/Colors';

const TotalTable = ({ dataSource }) => {
  const columns = [
    {
      title: 'Pay',
      dataIndex: 'pay',
      key: 'pay',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={FLEXTIME_COLOR}><strong>{text}</strong></font>,
    },
    {
      title: 'Worked',
      dataIndex: 'worked',
      key: 'worked',
      // eslint-disable-next-line react/display-name
      render: (text) => <font>{text}</font>,
    },
    {
      title: 'Paid Breaks',
      dataIndex: 'paidbreaks',
      key: 'paidbreaks',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={UNPAID_COLOR}>{text}</font>,
    },
    {
      title: 'Overtime',
      dataIndex: 'overtime',
      key: 'overtime',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={OVERTIME_COLOR}>{text}</font>,
    },
    {
      title: 'Sick',
      dataIndex: 'sicktime',
      key: 'sicktime',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={SICK_COLOR}>{text}</font>,
    },
    {
      title: 'Leave',
      dataIndex: 'leavetime',
      key: 'leavetime',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={LEAVETIME_COLOR}>{text}</font>,
    },
    {
      title: 'Unpaid',
      dataIndex: 'unpaid',
      key: 'unpaid',
      // eslint-disable-next-line react/display-name
      render: (text) => <font color={UNPAID_COLOR}>{text}</font>,
    },
  ];

  return (
    <Table
      style={{ overflow: 'auto' }}
      dataSource={dataSource}
      columns={columns}
      loading={false}
      rowKey="worked"
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

TotalTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      worked: PropTypes.number,
      overtime: PropTypes.number,
      sicktime: PropTypes.number,
      leavetime: PropTypes.number,
      unpaid: PropTypes.number,
      paidbreaks: PropTypes.number,
      pay: PropTypes.number,
    }).isRequired,
  ).isRequired,
};

export default TotalTable;

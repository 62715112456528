import { Auth } from 'aws-amplify';
import {
  Avatar, Drawer, Dropdown, Menu,
} from 'antd';
import { CrownOutlined, LogoutOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import UserForm from './UserForm';
import UserContext from '../../Context/User/UserContext';
import { getGravatar } from '../../Helpers/User';

const UserMenu = () => {
  const { me } = useContext(UserContext);
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="1" onClick={() => setDrawerVisible(true)}>
              <CrownOutlined />
              My profile
            </Menu.Item>
            <Menu.Item key="2" onClick={() => Auth.signOut()}>
              <LogoutOutlined />
              Sign out
            </Menu.Item>
          </Menu>
          )}
      >
        <Avatar src={getGravatar(me.attributes.email, me.attributes.nickname)} />

      </Dropdown>
      <Drawer
        title="Edit profile"
        placement="right"
        width={isMobile ? '80%' : '40%'}
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        destroyOnClose
      >
        <UserForm afterSubmit={() => setDrawerVisible(false)} model={me.attributes} />
      </Drawer>
    </>
  );
};

export default UserMenu;

import { createContext } from 'react';

export const defaultValue = {
  selected: undefined,
  setSelected: () => {},
};

const SidebarContext = createContext(defaultValue);

export default SidebarContext;

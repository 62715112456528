import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { PageHeader, Typography } from 'antd';
import FlextimeContext from '../../Context/Flextime/FlextimeContext';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import TimeWindowContext from '../../Context/TimeWindow/TimeWindowContext';
import TotalTable from './TotalTable';
import UserContext from '../../Context/User/UserContext';

const { Title } = Typography;

const TotalCard = () => {
  const { isAdmin } = useContext(UserContext);
  const { start, end } = useContext(TimeWindowContext);
  const { dataSource: flextimes } = useContext(FlextimeContext);
  const { dataSource: timeentries } = useContext(TimeEntryContext);
  const [worked, setWorked] = useState(0);
  const [paidbreaks, setPaidBreaks] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const [sicktime, setSicktime] = useState(0);
  const [leavetime, setLeavetime] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [pay, setPay] = useState(0);

  const round = (value, step) => {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
  };

  useEffect(() => {
    const sumFlextimes = () => {
      let accumulator = 0;
      flextimes.forEach(({ createdAt, updatedAt }) => {
        const timeDiff = moment(updatedAt).diff(moment(createdAt));
        accumulator += moment.duration(timeDiff).asHours();
      });

      return round(accumulator, 0.1);
    };

    const calculatePaidBreaks = () => {
      // As the govt; 10min of paid breaks for every 2 hours of work.
      const entitlement = ((sumFlextimes() / 2.5) * 10) / 60;
      return round(entitlement, 0.1);
    };

    const sumTimeEntries = (type) => {
      let accumulator = 0;
      timeentries.forEach(({ type: currentType, duration, approved }) => {
        if (approved && currentType === type) {
          accumulator += moment.duration(duration, 'seconds').asHours();
        }
      });

      return round(accumulator, 0.1);
    };

    const calculatePay = () => round((worked + paidbreaks), 0.5);

    setWorked(sumFlextimes());
    setPaidBreaks(calculatePaidBreaks());
    setOvertime(sumTimeEntries('OverTime'));
    setSicktime(sumTimeEntries('SickTime'));
    setLeavetime(sumTimeEntries('LeaveTime'));
    setUnpaid(sumTimeEntries('Unpaid'));
    setPay(calculatePay());
  }, [flextimes, timeentries, worked, paidbreaks, overtime, sicktime, leavetime, unpaid, pay]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={(
          <Title level={3}>
            {`Totals for: ${start.format('ddd DD')} - ${end.format('ddd DD')}`}
          </Title>
        )}
        bordered={false}
        style={{ width: '100%', overflow: 'auto' }}
        bodyStyle={{ padding: 0 }}
      >
        <TotalTable dataSource={[{
          worked, overtime, sicktime, leavetime, unpaid, paidbreaks, pay,
        }]}
        />
      </PageHeader>
    </>
  );
};

export default TotalCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectContext from './ProjectContext';
import { listModels } from '../../Helpers/GraphQL/Query';
import { onCreateModel, onUpdateModel } from '../../Helpers/GraphQL/Subscription';
import { deleteRecord } from '../../Helpers/GraphQL/Mutation';

const ProjectProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const deleteModel = (id) => {
    const key = 'deleteProject';

    deleteRecord(key, id).then(() => {
      setDataSource((prevDataSource) => prevDataSource.filter((record) => record.id !== id));
    });
  };

  if (!loaded) {
    setLoaded(true);
    const params = {};

    listModels('listProjects', null, null, null, null, setDataSource, setLoading);

    subscriptions.map((subscription) => subscription.unsubscribe());
    setSubscriptions([
      onCreateModel('onCreateProjectForAdmins', params, setDataSource, setLoading),
      onUpdateModel('onUpdateProjectForAdmins', params, setDataSource, setLoading),
    ]);
  }

  return (
    <ProjectContext.Provider value={{
      dataSource, loading, deleteModel,
    }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectProvider;

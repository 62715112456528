import React, { useContext } from 'react';
import {
  Select, Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import YoutrackContext from '../../Context/Youtrack/YoutrackContext';

const { Option } = Select;

const YoutrackSelect = ({ selected, onChange }) => {
  const { dataSource, loading } = useContext(YoutrackContext);

  return (
    <>
      { loading ? <Skeleton.Input active /> : (
        <Select
          showSearch
          placeholder="Select a project"
          onChange={onChange}
          value={selected}
          filterOption={(
            input,
            {
              props: { children },
            },
          ) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            dataSource.filter(({ archived }) => !archived).map(
              ({ name, shortName }) => (
                <Option
                  key={shortName}
                  value={shortName}
                >
                  {name}
                </Option>
              ),
            )
          }
        </Select>
      )}
    </>

  );
};

YoutrackSelect.defaultProps = {
  onChange: () => {},
  selected: null,
};

YoutrackSelect.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string,

};

export default YoutrackSelect;

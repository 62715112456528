import React from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import '@ant-design/compatible/assets/index.css';
import {
  Layout,
} from 'antd';
import config from './aws-exports'; // eslint-disable-line
import Routes from './Components/Routes';
import GlobalState from './Context/GlobalState';
import Topbar from './Components/Layout/Topbar';
import Sidebar from './Components/Layout/Sidebar';

Amplify.configure(config);

const { Content, Footer } = Layout;

const App = () => (
  <GlobalState>
    <Router history={createBrowserHistory()}>
      <Layout>
        <Topbar />
        <Layout style={{ minHeight: '100vh' }}>
          <Sidebar />
          <Layout>
            <Content>
              <div style={{ background: '#fff', padding: 24, minHeight: 280 }}><Routes /></div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>TaskHub by Communica</Footer>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  </GlobalState>
);

const signUpConfig = {
  hideAllDefaults: true,
  defaultCountryCode: '64',
  usernameAttributes: 'email',
  signUpFields: [
    {
      label: 'Given name',
      key: 'given_name',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Family name',
      key: 'family_name',
      required: true,
      displayOrder: 2,
      type: 'string',
    },
    {
      label: 'Nickname',
      key: 'nickname',
      required: true,
      displayOrder: 3,
      type: 'string',
    },
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 4,
      type: 'string',
    },
    {
      label: 'Phone',
      key: 'phone_number',
      required: true,
      displayOrder: 5,
      type: 'phone',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 5,
      type: 'password',
    },
  ],
};

export default withAuthenticator(App, { signUpConfig });

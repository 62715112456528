import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import ConnectorContext from '../Connector/ConnectorContext';
import XeroContext from './XeroContext';
import { notify } from '../../Helpers/Error';

const XeroProvider = ({ children }) => {
  const { dataSource: connectors } = useContext(ConnectorContext);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async (connector) => {
      setLoading(true);

      const path = '/api/xero/contacts';

      return axios.request({
        url: process.env.REACT_APP_TASKHUB_PROXY_HOST + path,
        method: 'post',
        data: {
          authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          connector,
        },
      }).then(({ data }) => {
        setDataSource(data);
        setLoading(false);
      }).catch((err) => {
        notify(err, 'Error on xero provider');
        setLoading(false);
        setError(true);
      });
    };

    if (!loading && !error && !dataSource.length) {
      const xeroConnector = connectors.find((connector) => connector.service === 'xero');
      if (xeroConnector) {
        fetchData(xeroConnector).then();
      }
    }
  }, [dataSource, error, loading, connectors]);

  return (
    <XeroContext.Provider value={{ dataSource, loading }}>
      {children}
    </XeroContext.Provider>
  );
};

XeroProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default XeroProvider;

import React from 'react';
import randombytes from 'randombytes';
import createHash from 'create-hash';

function base64URLEncode(str) {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer) {
  return createHash('sha256').update(buffer).digest();
}

const verifier = base64URLEncode(randombytes(32));
const codeChallenge = base64URLEncode(sha256(verifier));

const config = {
  client: {
    id: 'F5738E4E966D40CCACE81E0316F566C4',
    secret: '-TvFQzRLQj7B-zbvT9NLrDYl43hEkf3AMR4_0ecX4kMFftI2',
  },
  auth: {
    tokenHost: 'https://login.xero.com/identity/connect/authorize',
    accessTokenUri: 'https://identity.xero.com/connect/token',
    redirectUri: 'http://localhost:3000/redirect',
    scope: 'openid profile email accounting.transactions offline_access',
  },
};

const url = new URL(config.auth.tokenHost);
const params = {
  response_type: 'code',
  client_id: config.client.id,
  scope: config.auth.scope,
  redirect_uri: config.auth.redirectUri,
  code_challenge: codeChallenge,
  code_challenge_method: 'S256',

};
Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

const Xero = () => {
  localStorage.setItem('verifier', verifier);
  localStorage.setItem('codeChallenge', codeChallenge);

  return (
    <>
      <div>Project</div>
      <div>{`Request uri: ${url.toString()}`}</div>
      <a href={url.toString()} target="_blank" rel="noopener noreferrer">link</a>
    </>
  );
};

export default Xero;

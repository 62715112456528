import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import axios from 'axios';
import { Space, Spin } from 'antd';
import moment from 'moment';
import { onFinish } from '../Helpers/Form';
import UserContext from '../Context/User/UserContext';
import { notify } from '../Helpers/Error';

const Redirect = () => {
  const { me } = useContext(UserContext);
  const { referrer } = useParams();
  const { search } = useLocation();
  const { code } = queryString.parse(search);

  const verifier = localStorage.getItem('verifier');

  const createXeroConnector = () => {
    const params = {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_XERO_APP_CLIENT_ID,
      code,
      redirect_uri: process.env.REACT_APP_XERO_APP_REDIRECT,
      code_verifier: verifier,
    };

    axios.post(process.env.REACT_APP_XERO_APP_AUTH_TOKEN, queryString.stringify(params))
      .then(({ data: { access_token, expires_in, refresh_token } }) => {
        onFinish('connector', {
          name: 'Xero OAuth 2.0',
          owner: me.username,
          service: 'xero',
          access_token,
          expiry: moment().add(expires_in, 'seconds').unix(),
          refresh_token,
        }, () => window.close(), () => {});
      })
      .catch((err) => notify(err));
  };

  const createYouTrackConnector = () => {
    const params = {
      grant_type: 'authorization_code',
      code,
      redirect_uri: process.env.REACT_APP_YOUTRACK_APP_REDIRECT,
      client_id: process.env.REACT_APP_YOUTRACK_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_YOUTRACK_APP_CLIENT_SECRET,
    };

    axios.post(process.env.REACT_APP_YOUTRACK_APP_AUTH_TOKEN, queryString.stringify(params))
      .then(({ data }) => {
        const { access_token, expires_in } = data;
        onFinish('connector', {
          name: 'Youtrack OAuth 2.0',
          owner: me.username,
          service: 'youtrack',
          access_token,
          expiry: moment().add(expires_in, 'seconds').unix(),
          service_url: 'https://youtrack.communica.nz',
          bearer_token: access_token,
        }, () => window.close(), () => {});
      })
      .catch((err) => notify(err));
  };

  switch (referrer) {
    case 'xero':
      createXeroConnector();
      break;
    case 'youtrack':
      createYouTrackConnector();
      break;
    default:
      break;
  }

  return (
    <Space size="middle">
      <Spin size="large" />
    </Space>
  );
};

export default Redirect;

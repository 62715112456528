import React from 'react';
import {
  Form, Input, InputNumber, Select,
} from 'antd';
import PropTypes from 'prop-types';
import IssuesStateOptions from '../Issue/IssuesStateOptions';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = {
    summary: <Input />,
    rate: <InputNumber />,
    state: <Select style={{ width: 150 }} options={IssuesStateOptions} />,
  };

  const rules = {
    summary: [{
      required: true,
      message: `${title} is required.`,
    }],
    rate: [{
      required: true,
      message: `${title} is required.`,
    },
    {
      type: 'number',
      message: `${title} must be a number.`,
    },
    ],
  };

  return (
    <td
      colSpan={restProps.colSpan}
      rowSpan={restProps.rowSpan}
      style={restProps.style}
      className={restProps.className}
    >
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules[dataIndex]}
        >
          {inputNode[dataIndex]}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.defaultProps = {
  editing: undefined,
  dataIndex: undefined,
  title: undefined,
  inputType: undefined,
  record: undefined,
  index: undefined,
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.oneOf(['number', 'text']),
  record: PropTypes.shape({}),
  index: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default EditableCell;

import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import YoutrackIssuesSelectedContext from '../../Context/Youtrack/Issues/Selected/YoutrackIssuesSelectedContext';
import YoutrackIssuesTypeContext from '../../Context/Youtrack/Issues/Type/YoutrackIssuesTypeContext';

const IssuesBillByFeatureCheckbox = () => {
  const { reset } = useContext(YoutrackIssuesSelectedContext);
  const { change } = useContext(YoutrackIssuesTypeContext);

  const onChange = () => {
    reset();
    change();
  };

  return (
    <Checkbox onChange={() => onChange()}>Bill by feature</Checkbox>

  );
};

export default IssuesBillByFeatureCheckbox;

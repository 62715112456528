import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router';
import UserContext from '../../Context/User/UserContext';
import SidebarContext from '../../Context/Sidebar/SidebarContext';
import { convertUserIdToAttribute, convertUserIdToEmail, getGravatar } from '../../Helpers/User';

const { Sider } = Layout;

const Sidebar = () => {
  const {
    users, me, loaded, isAdmin, isAccountant,
  } = useContext(UserContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const { selected, setSelected } = useContext(SidebarContext);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const localCollapsed = localStorage.getItem('collapsed');
    if (localCollapsed !== null) {
      setCollapsed(JSON.parse(localCollapsed));
    }
  }, []);

  const onCollapse = (event) => {
    setCollapsed(event);
    localStorage.setItem('collapsed', event);
  };

  const handleSelect = (key) => {
    history.push('/');
    setSelected(key);
  };

  let userList = [{
    ...me,
    Username: me.username,
  }];

  if (users && users.length) {
    userList = users;
  }

  if (!loaded || (!isAdmin && !isAccountant) || pathname !== '/') {
    return null;
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu theme="dark" selectedKeys={selected} onSelect={({ key }) => handleSelect(key)} mode="inline">
        { userList.map(({ Username }) => {
          const email = convertUserIdToEmail(users, Username);
          const nickname = convertUserIdToAttribute(users, Username, 'nickname');
          const givenName = convertUserIdToAttribute(users, Username, 'given_name');

          return (
            <Menu.Item
              key={Username}
              icon={(
                <Avatar
                  className="anticon ant-avatar ant-avatar-circle ant-avatar-image"
                  src={getGravatar(email, nickname)}
                  style={{ backgroundColor: '#1890ff' }}
                />
                )}
            >
              {givenName}
            </Menu.Item>
          );
        })}

      </Menu>
    </Sider>
  );
};

export default Sidebar;

import React, { useContext, useEffect, useState } from 'react';
import {
  PageHeader, Drawer, Typography,
} from 'antd';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import TimeEntriesTable from './TimeEntriesTable';
import { fromSeconds } from '../../Helpers/Duration';
import TimeEntryForm from './TimeEntryForm';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import SidebarContext from '../../Context/Sidebar/SidebarContext';

const { Title } = Typography;

const TimeEntryCard = () => {
  const { timeOptions } = useContext(TimeEntryContext);
  const { selected } = useContext(SidebarContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [model, setModel] = useState({});
  const { id } = model;

  const edit = (event) => {
    const { date, duration } = event;
    setModel({
      ...event,
      date: moment(date),
      duration: fromSeconds(duration),
    });
    setDrawerVisible(true);
  };

  useEffect(() => {
    setModel({
      owner: selected,
      date: moment(),
      type: timeOptions.length ? timeOptions[0].name : null,
      duration: '8h',
      approved: false,
    });
  }, [selected, timeOptions]);

  return (
    <PageHeader
      style={{ width: '100%' }}
      bordered={false}
      title={<Title level={3}>Activity adjustment entry</Title>}
      bodyStyle={{ padding: 0 }}
    >
      <TimeEntriesTable onClickEdit={edit} onClickCreate={() => setDrawerVisible(true)} />
      <Drawer
        title={`${id ? 'Edit' : 'Create a new'} record`}
        placement="right"
        width={isMobile ? '80%' : '40%'}
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        destroyOnClose
      >
        <TimeEntryForm afterSubmit={() => setDrawerVisible(false)} model={model} />
      </Drawer>
    </PageHeader>
  );
};

export default TimeEntryCard;

import React, { useContext } from 'react';
import {
  Button, Popconfirm, Switch, Table,
} from 'antd';
import {
  DeleteOutlined, EditOutlined, WarningOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import UserContext from '../../Context/User/UserContext';
import { fromSeconds } from '../../Helpers/Duration';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';

const TimeEntriesTable = ({ onClickEdit, onClickCreate }) => {
  const { isAdmin, isAccountant } = useContext(UserContext);
  const {
    loading, dataSource, setApproved, deleteModel,
  } = useContext(TimeEntryContext);

  const renderOperations = (id, row) => {
    const { approved } = row;

    if (!id || (approved && !isAdmin && !isAccountant)) {
      return null;
    }

    return (
      <>
        <div className="table-ghost-wrapper">
          <Button type="primary" ghost shape="circle" onClick={() => onClickEdit(row)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            okType="danger"
            icon={<WarningOutlined style={{ color: 'red' }} />}
            onConfirm={() => deleteModel(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" ghost shape="circle">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      </>
    );
  };

  const renderApproved = (text, { id }) => {
    if (id) {
      return (
        <Switch
          checked={text}
          onChange={(event) => setApproved(id, event)}
        />
      );
    }
    return null;
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => {
        if (!text) {
          return (
            <Button
              type="primary"
              loading={loading}
              onClick={onClickCreate}
            >
              Create
            </Button>
          );
        }

        return text;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Time',
      dataIndex: 'duration',
      key: 'duration',
      render: fromSeconds,
    },

  ];

  if (isAdmin || isAccountant) {
    columns.push({
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: renderApproved,
    });
  }

  columns.push({
    title: 'Operation',
    dataIndex: 'id',
    key: 'operation',
    render: renderOperations,
    align: 'center',
  });

  return (
    <Table
      style={{ overflow: 'auto' }}
      dataSource={[{ id: '' }, ...dataSource]}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

TimeEntriesTable.defaultProps = {
  onClickEdit: () => {},
  onClickCreate: () => {},
};

TimeEntriesTable.propTypes = {
  onClickEdit: PropTypes.func,
  onClickCreate: PropTypes.func,
};

export default TimeEntriesTable;

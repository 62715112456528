import React, { useState, useContext } from 'react';
import {
  Form, Button, Input, InputNumber, Radio,
} from 'antd';
import PropTypes from 'prop-types';
import YoutrackSelect from '../Youtrack/YoutrackSelect';
import BugheardSelect from '../Bugheard/BugheardSelect';
import XeroSelect from '../Xero/XeroSelect';
import YoutrackContext from '../../Context/Youtrack/YoutrackContext';
import { onFinish } from '../../Helpers/Form';

const ProjectForm = ({ initialValues, afterSubmit }) => {
  const [form, setForm] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { dataSource } = useContext(YoutrackContext);

  const onChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const finish = (fieldsValue) => {
    const input = fieldsValue;
    const project = dataSource.find((youtrack) => youtrack.shortName === form.youtrack);
    input.name = project.name;
    input.currency = input.currency ?? 'NZD';

    onFinish(
      'project',
      input,
      afterSubmit,
      setLoading,
    );
  };

  return (
    <Form
      name="project-form"
      onFinish={finish}
      initialValues={initialValues}
      labelCol={{ span: 9 }}
    >
      <Form.Item name="id" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="owner" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="youtrack" label="Select a Youtrack project" rules={[{ required: true, message: 'Please select a project!' }]}>
        <YoutrackSelect onChange={(value) => onChange({ target: { name: 'youtrack', value } })} selected={form.youtrack} />
      </Form.Item>

      <Form.Item name="bugherd" label="Select a BH project">
        <BugheardSelect onChange={(value) => onChange({ target: { name: 'bugherd', value: value.toString() } })} selected={form.bugherd} />
      </Form.Item>

      <Form.Item name="xero" label="Select a Xero contact" rules={[{ required: true, message: 'Please select a contact!' }]}>
        <XeroSelect onChange={(value) => onChange({ target: { name: 'xero', value: value.toString() } })} selected={form.xero} />
      </Form.Item>

      <Form.Item name="rate" label="Hourly Rate" rules={[{ required: true }]}>
        <InputNumber
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item name="currency" label="Currency" rules={[{ required: true }]}>
        <Radio.Group options={['AUD', 'NZD']} optionType="button" buttonStyle="solid" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

ProjectForm.defaultProps = {
  afterSubmit: () => {},
};

ProjectForm.propTypes = {
  afterSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    currency: PropTypes.string,
  }).isRequired,
};

export default ProjectForm;

import crypto from 'crypto';

export const getFromCollection = (
  users,
  userId,
) => users.find(({ Username }) => Username === userId);

export const getItemAttribute = (users, id, field) => {
  const user = getFromCollection(users, id);
  if (user) {
    const { Attributes } = user;
    if (Attributes) {
      const attribute = Attributes.find(({ Name }) => Name === field);
      if (attribute) {
        const { Value } = attribute;
        if (Value) {
          return Value;
        }
      }
    }
  }

  return '';
};

export const convertUserIdToAttribute = (
  collection,
  id,
  attribute,
) => getItemAttribute(collection, id, attribute);

export const convertUserIdToEmail = (collection, id) => {
  if (!collection.length) {
    return id;
  }
  return convertUserIdToAttribute(collection, id, 'email');
};

export const getGravatar = (email, name = null) => {
  const md5 = (data) => crypto.createHash('md5').update(data).digest('hex');
  const md5Email = md5(email);

  let url = `https://www.gravatar.com/avatar/${md5Email}`;

  if (name) {
    url += `?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${encodeURIComponent(name)}/128`;
  }

  return url;
};

const User = {
  convertUserIdToEmail,
  convertUserIdToAttribute,
};

export default User;

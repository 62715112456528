import { API, Auth } from 'aws-amplify';

const limit = 50;

export const execute = async (path, parameters = {}, method = 'get') => {
  const apiName = 'AdminQueries';
  const myInit = {
    queryStringParameters: parameters,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  if (method !== 'get') {
    myInit.body = myInit.queryStringParameters;
    delete myInit.queryStringParameters;
  }

  return API[method](apiName, path, myInit);
};

export const listUsers = async () => {
  const { Users } = await execute(
    '/listUsers',
    {
      limit,
    },
  );

  return Users;
};

export const listUsersInGroup = async (groupname = 'Users') => {
  const { Users } = await execute(
    '/listUsersInGroup',
    {
      limit,
      groupname,
    },
  );

  return Users;
};

export const updateUserAttributes = async (username, attributes) => {
  const userAttributes = Object.keys(attributes).map(
    (name) => ({ Name: name, Value: attributes[name] }),
  );
  return execute(
    '/updateUserAttributes',
    {
      username,
      attributes: userAttributes,
    },
    'put',
  );
};

export const deleteUser = async (username) => execute(
  '/deleteUser',
  {
    username,
  },
  'del',
);

export const AdminQueries = {
  listUsers,
  listUsersInGroup,
  updateUserAttributes,
  deleteUser,
};

export default AdminQueries;

export const edit = (event, setModel, setDrawerVisible) => {
  setModel(event);
  setDrawerVisible(true);
};

export const Card = {
  edit,
};

export default Card;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Popconfirm, Switch } from 'antd';
import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, WarningOutlined,
} from '@ant-design/icons';
import ConnectorContext from '../../Context/Connector/ConnectorContext';

const ConnectorList = ({ onClickEdit }) => {
  const { dataSource, deleteModel } = useContext(ConnectorContext);

  return (
    <>
      { dataSource.map((model) => {
        const { id, name, service } = model;

        return (
          <Card
            key={id}
            style={{ width: 300 }}
            title={name}
            actions={[
              <EditOutlined
                key={`edit-${id}`}
                onClick={() => onClickEdit(model)}
              />,
              <Popconfirm
                key={`delete-pop-${id}`}
                title="Are you sure to delete this record?"
                okType="danger"
                icon={<WarningOutlined style={{ color: 'red' }} />}
                onConfirm={() => deleteModel(id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined key={`delete-${id}`} />
              </Popconfirm>,
              <Switch
                key={`setStatus-${id}`}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                disabled
              />,
            ]}
          >
            {`Service: ${service}`}
          </Card>
        );
      })}
    </>
  );
};

ConnectorList.defaultProps = {
  onClickEdit: PropTypes.func,
};

ConnectorList.propTypes = {
  onClickEdit: PropTypes.func,
};

export default ConnectorList;

import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import UserContext from './UserContext';
import { listUsersInGroup } from '../../Helpers/AdminQueries';

const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [me, setMe] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAccountant, setIsAccountant] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const updateUsers = (id, attributes) => {
    const newCollection = [...users];

    newCollection.forEach((element, index) => {
      if (element.Username === id) {
        newCollection[index].Attributes = Object.keys(attributes).map(
          (name) => ({ Name: name, Value: attributes[name] }),
        );
      }
    });

    setUsers(newCollection);
  };

  const deleteUser = (id) => setUsers(users.filter((user) => user.Username !== id));

  const loadMe = () => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((currentUser) => { setMe(currentUser); });
  };

  useEffect(() => {
    const hasRole = (currentUser, role) => {
      const {
        signInUserSession: {
          accessToken: { payload },
        },
      } = currentUser;

      if ('cognito:groups' in payload) {
        const groups = payload['cognito:groups'];
        if (groups.includes(role)) {
          return true;
        }
      }

      return false;
    };

    const fetchData = async () => {
      setLoaded(false);
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setMe(currentUser);

      const currentUserIsAdmin = hasRole(currentUser, 'Admins');
      const currentUserIsAccountant = hasRole(currentUser, 'Accountants');
      const currentUserIsManager = hasRole(currentUser, 'Managers');

      setIsAdmin(currentUserIsAdmin);
      setIsAccountant(currentUserIsAccountant);
      setIsManager(currentUserIsManager);

      if (currentUserIsAdmin || currentUserIsAccountant) {
        const currentUsers = await listUsersInGroup();
        setUsers(currentUsers);
      }
    };

    fetchData().then(() => setLoaded(true));
  }, []);

  return (
    <UserContext.Provider value={{
      isAdmin, isAccountant, isManager, loaded, me, users, loadMe, updateUsers, deleteUser,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;

import React, { useContext } from 'react';
import { Button, Popconfirm, Table } from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import UserContext from '../../Context/User/UserContext';
import { deleteUser } from '../../Helpers/AdminQueries';

const UsersTable = ({ onClickEdit }) => {
  const {
    isAdmin, loading, deleteUser: deleteUserFromContext, users: dataSource,
  } = useContext(UserContext);

  const onClickDelete = (username) => deleteUser(username)
    .then(() => deleteUserFromContext(username));

  const renderOperations = (id, row) => {
    const { approved } = row;

    if (!id || (approved && !isAdmin)) {
      return null;
    }

    return (
      <>
        <Button type="primary" ghost shape="circle" onClick={() => onClickEdit(row)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Are you sure to delete this record?"
          okType="danger"
          icon={<WarningOutlined style={{ color: 'red' }} />}
          onConfirm={() => onClickDelete(id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" ghost shape="circle" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    );
  };

  const parseDataSource = () => {
    const users = dataSource.map((user) => {
      const { Username, Attributes } = user;
      const currentUser = {
        id: Username,
        attributes: {},
      };

      if (Attributes && Attributes.length) {
        Attributes.forEach(({ Name, Value }) => {
          currentUser.attributes[Name] = Value;
        });
      }

      return currentUser;
    });

    return users;
  };

  const columns = [
    {
      title: 'Given Name',
      dataIndex: 'attributes',
      key: 'given_name',
      render: ({ given_name: text }) => text,
    },
    {
      title: 'Family Name',
      dataIndex: 'attributes',
      key: 'family_name',
      render: ({ family_name: text }) => text,
    },
    {
      title: 'Nickname',
      dataIndex: 'attributes',
      key: 'nickname',
      render: ({ nickname }) => nickname,
    },
    {
      title: 'Email',
      dataIndex: 'attributes',
      key: 'email',
      render: ({ email }) => email,
    },
    {
      title: 'Phone number',
      dataIndex: 'attributes',
      key: 'nickname',
      render: ({ phone_number: text }) => text,
    },
    {
      title: 'Hours per week',
      dataIndex: 'attributes',
      key: 'custom:hours_per_week',
      render: ({ 'custom:hours_per_week': text }) => text,
    },
    {
      title: 'Operation',
      dataIndex: 'id',
      key: 'operation',
      render: renderOperations,
    },
  ];

  return (
    <Table
      dataSource={parseDataSource()}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

UsersTable.defaultProps = {
  onClickEdit: () => {},
};

UsersTable.propTypes = {
  onClickEdit: PropTypes.func,
};

export default UsersTable;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { EyeOutlined } from '@ant-design/icons';

const ProjectButton = ({ shortName }) => (
  <Tooltip title="View issues">
    <Link to={`/project/${shortName}/issues`}>
      <Button shape="circle">
        <EyeOutlined />
      </Button>
    </Link>
  </Tooltip>
);

ProjectButton.propTypes = {
  shortName: PropTypes.string.isRequired,
};

export default ProjectButton;

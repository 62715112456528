import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import ConnectorContext from './ConnectorContext';
import { getEnumValues } from '../../Helpers/GraphQL/Response';
import UserContext from '../User/UserContext';
import { listEnumValues, listModels } from '../../Helpers/GraphQL/Query';
import { onCreateModel, onUpdateModel } from '../../Helpers/GraphQL/Subscription';
import { deleteRecord } from '../../Helpers/GraphQL/Mutation';

const ConnectorProvider = ({ children }) => {
  const { me } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [services, setServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const deleteModel = (id) => {
    const key = 'deleteConnector';

    deleteRecord(key, id).then(() => {
      setDataSource((prevDataSource) => prevDataSource.filter((record) => record.id !== id));
    });
  };

  useEffect(() => {
    if (!loaded) {
      const params = { owner: me.username };

      listModels('listConnectors', me.username, null, null, null, setDataSource, setLoading);
      setLoaded(true);

      API.graphql(graphqlOperation(listEnumValues, { enum: 'Service' })).then((response) => {
        setServices(getEnumValues(response));
      });

      subscriptions.map((subscription) => subscription.unsubscribe());

      setSubscriptions([
        onCreateModel('onCreateConnector', params, setDataSource, setLoading),
        onUpdateModel('onUpdateConnector', params, setDataSource, setLoading),
      ]);
    }
  }, [me, subscriptions, loaded]);

  return (
    <ConnectorContext.Provider value={{
      dataSource, loading, services, deleteModel,
    }}
    >
      {children}
    </ConnectorContext.Provider>
  );
};

ConnectorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConnectorProvider;

export const FLEXTIME_COLOR = '#1ca9e6';
export const SICK_COLOR = '#B746C8';
export const OVERTIME_COLOR = '#f88c24';
export const LEAVETIME_COLOR = '#7cd857';
export const UNPAID_COLOR = '#868686';

const Colors = [
  FLEXTIME_COLOR,
  SICK_COLOR,
  OVERTIME_COLOR,
  LEAVETIME_COLOR,
  UNPAID_COLOR,
];

export default Colors;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SidebarContext from './SidebarContext';
import UserContext from '../User/UserContext';

const SidebarProvider = ({ children }) => {
  const { loaded, me, users } = useContext(UserContext);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (loaded) {
      setSelected(me.username);

      if (users && users.length) {
        setSelected(users[0].Username);
      }
    }
  }, [loaded, me, users]);

  if (!loaded || !selected) {
    return null;
  }

  return (
    <SidebarContext.Provider value={{ selected, setSelected }}>
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarProvider;

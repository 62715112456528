/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConnector = /* GraphQL */ `
  query GetConnector($id: ID!) {
    getConnector(id: $id) {
      id
      name
      owner
      service
      service_url
      bearer_token
      access_token
      refresh_token
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const listConnectors = /* GraphQL */ `
  query ListConnectors(
    $filter: ModelConnectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        service
        service_url
        bearer_token
        access_token
        refresh_token
        expiry
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlextime = /* GraphQL */ `
  query GetFlextime($id: ID!) {
    getFlextime(id: $id) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listFlextimes = /* GraphQL */ `
  query ListFlextimes(
    $filter: ModelFlextimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlextimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      youtrack
      xero
      bugherd
      rate
      currency
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        youtrack
        xero
        bugherd
        rate
        currency
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimeEntry = /* GraphQL */ `
  query GetTimeEntry($id: ID!) {
    getTimeEntry(id: $id) {
      id
      owner
      date
      duration
      approved
      type
      createdAt
      updatedAt
    }
  }
`;
export const listTimeEntrys = /* GraphQL */ `
  query ListTimeEntrys(
    $filter: ModelTimeEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        date
        duration
        approved
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFlexTimesByUpdatedAt = /* GraphQL */ `
  query ListFlexTimesByUpdatedAt(
    $owner: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlextimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlexTimesByUpdatedAt(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

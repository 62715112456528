import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import randombytes from 'randombytes';
import createHash from 'create-hash';
import { ReactComponent as Logo } from '../../Assets/xero/logo.svg';
import ConnectorContext from '../../Context/Connector/ConnectorContext';

const XeroConnectButton = () => {
  const { dataSource } = useContext(ConnectorContext);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const connector = dataSource.find((item) => item.service === 'xero');
    setConnected(!!connector);
  }, [dataSource]);

  const base64URLEncode = (str) => str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  const sha256 = (buffer) => createHash('sha256').update(buffer).digest();

  const disconnect = () => {
    setConnected(false);
  };

  const connect = () => {
    const verifier = base64URLEncode(randombytes(32));
    const codeChallenge = base64URLEncode(sha256(verifier));
    const width = 600;
    const height = 860;
    const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

    const url = new URL(process.env.REACT_APP_XERO_APP_AUTH_HOST);
    const params = {
      response_type: 'code',
      client_id: process.env.REACT_APP_XERO_APP_CLIENT_ID,
      scope: process.env.REACT_APP_XERO_APP_SCOPE,
      redirect_uri: process.env.REACT_APP_XERO_APP_REDIRECT,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    window.open(url.toString(), 'popup', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);
    localStorage.setItem('verifier', verifier);
  };

  const onClick = () => {
    if (connected) {
      return disconnect();
    }

    return connect();
  };

  return (
    <div role="presentation" onClick={onClick}>
      <Button icon={<Icon component={Logo} size="large" />}>
        {!connected ? 'Connect to Xero' : 'Disconnect from Xero'}
      </Button>
    </div>
  );
};

export default XeroConnectButton;

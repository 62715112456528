import React, { useState } from 'react';
import PropTypes from 'prop-types';
import YoutrackIssuesTypeContext from './YoutrackIssuesTypeContext';

const YoutrackIssuesProvider = ({ children }) => {
  const [type, setType] = useState('task');
  const [selected, setSelected] = useState(['Task', 'Bug']);

  const change = () => {
    const nextType = type === 'feature' ? 'task' : 'feature';
    setType(nextType);

    switch (nextType) {
      case 'feature':
        setSelected(['Feature']);
        break;
      default:
        setSelected(['Task', 'Bug']);
        break;
    }
  };

  return (
    <YoutrackIssuesTypeContext.Provider value={{
      change, selected, setSelected, type,
    }}
    >
      {children}
    </YoutrackIssuesTypeContext.Provider>
  );
};

YoutrackIssuesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default YoutrackIssuesProvider;

import React, { useContext, useEffect, useState } from 'react';
import {
  Typography, Row, Col, PageHeader,
} from 'antd';
import FlextimeCard from '../Components/FlexTime/FlextimeCard';
import TimeEntryCard from '../Components/TimeEntry/TimeEntryCard';
import TimeWindowPicker from '../Components/TimeWindow/TimeWindowPicker';
import ActivityBarChart from '../Components/Chart/ActivityBarChart';
import ActivityCircleBarChart from '../Components/Chart/ActivityCircleBarChart';
import TimeWindowContext from '../Context/TimeWindow/TimeWindowContext';
import TotalCard from '../Components/Total/TotalCard';
import SidebarContext from '../Context/Sidebar/SidebarContext';
import UserContext from '../Context/User/UserContext';
import { convertUserIdToAttribute } from '../Helpers/User';

const { Title } = Typography;

const TimeLog = () => {
  const [fragments, setFragments] = useState([]);
  const {
    start,
    end,
    days,
    model,
  } = useContext(TimeWindowContext);
  const { users } = useContext(UserContext);
  const { selected } = useContext(SidebarContext);
  const [user, setUser] = useState('');

  useEffect(() => {
    const createFragments = () => {
      const totalWeeks = end.diff(start, 'weeks');
      const periods = [];

      for (let i = 0; i <= totalWeeks; i += 1) {
        const s = start.clone().add(i, 'week');
        const e = s.clone().add(1, 'week').subtract(1, 'day').endOf('day');
        periods.push({ start: s, end: e });
      }

      setFragments(periods);
    };

    if ((fragments.length < 2 && days) || (fragments.length && !fragments[0].start.isSame(start))) {
      createFragments();
    }

    if (users.length) {
      const userExist = users.find((item) => item.Username === selected);
      if (userExist) {
        const firstName = convertUserIdToAttribute(users, userExist.Username, 'given_name');
        const lastName = convertUserIdToAttribute(users, userExist.Username, 'family_name');
        setUser(`${firstName} ${lastName}`);
      }
    }
  }, [days, model, users, selected, start, end, fragments]);

  return (
    <>
      <PageHeader
        style={{ width: '100%' }}
        bordered={false}
        title={<Title>{`Time Log - ${user}`}</Title>}
        extra={(
          <TimeWindowPicker />
        )}
        bodyStyle={{ padding: 0 }}
      />

      <Row gutter={[16, 16]} align="middle" justify="center" style={{ alignItems: 'center' }}>
        {
          fragments.map((fragment) => {
            const { start: fragmentStart, end: fragmentEnd } = fragment;
            return (
              <Col md={12} sm={24} key={`activity-circle-col-${fragmentStart.format('ddd DD')}`}>
                <ActivityCircleBarChart period={fragment} />
                <Title level={4} style={{ textAlign: 'center' }}>
                  {`${fragmentStart.format('ddd DD')} - ${fragmentEnd.format('ddd DD')}`}
                </Title>
              </Col>
            );
          })
        }
      </Row>

      <Row gutter={[16, 16]} align="middle" justify="center" style={{ alignItems: 'center', marginBottom: '30px' }}>
        { fragments.map((fragment) => {
          const { start: fragmentStart } = fragment;
          return (
            <Col md={12} sm={24} key={`activity-circle-col-${fragmentStart.format('ddd DD')}`}>
              <ActivityBarChart period={fragment} />
            </Col>
          );
        })}
      </Row>

      <TotalCard />
      <FlextimeCard />
      <TimeEntryCard />

    </>

  );
};
export default TimeLog;

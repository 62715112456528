import axios from 'axios';

const baseURL = 'https://dev-taskhub-proxies.communica.nz/api/bugherd';
const headers = {
  'Access-Control-Allow-Origin': '*',
};

const config = {
  baseURL,
  headers,
};

const instance = axios.create(config);

export default instance;

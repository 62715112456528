import React, { useState } from 'react';
import {
  Form, Button, Input, DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import { onFinish } from '../../Helpers/Form';

const FlextimeForm = ({ model, afterSubmit }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Form
      name="sick-form"
      onFinish={(fieldsValue) => onFinish(
        'flextime',
        fieldsValue,
        afterSubmit,
        setLoading,
      )}
      initialValues={model}
      labelCol={{ span: 4 }}
    >

      <Form.Item name="id" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="owner" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="createdAt" label="Start" rules={[{ required: true }]}>
        <DatePicker
          showTime
        />
      </Form.Item>

      <Form.Item name="updatedAt" label="Stop" rules={[{ required: true }]}>
        <DatePicker
          showTime
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

FlextimeForm.defaultProps = {
  afterSubmit: () => {},
};

FlextimeForm.propTypes = {
  afterSubmit: PropTypes.func,
  model: PropTypes.shape({}).isRequired,
};

export default FlextimeForm;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { dataToCSV } from '../../Helpers/CSV';

const IssuesToCsv = ({ data }) => {
  const headers = [
    { label: 'Parent', key: 'parent_id' },
    { label: '#', key: 'id' },
    { label: 'Description', key: 'summary' },
    { label: 'Amount', key: 'price' },
  ];

  return (
    <CSVLink
      filename={`Issues_${moment().format('D-M-Y')}.csv`}
      data={dataToCSV(data, headers)}
    >
      Download CSV
    </CSVLink>
  );
};

IssuesToCsv.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default IssuesToCsv;

import React, { useContext, useEffect, useState } from 'react';
import {
  Form, Button, Input, Radio,
} from 'antd';
import PropTypes from 'prop-types';
import { onChange, onFinish } from '../../Helpers/Form';
import ConnectorContext from '../../Context/Connector/ConnectorContext';
import { capitalize } from '../../Helpers/String';

const ConnectorForm = ({ model, afterSubmit }) => {
  const { services } = useContext(ConnectorContext);
  const [form, setForm] = useState(model);
  const [loading, setLoading] = useState(false);
  const [authorizationFields, setAuthorizationFields] = useState([]);

  const mustDisplayField = (fieldName) => authorizationFields.find(
    ({ name }) => name === fieldName,
  );

  useEffect(() => {
    const mapFields = [
      { name: 'service_url', authorization: ['bearer'] },
      { name: 'bearer_token', authorization: ['bearer'] },
      { name: 'access_token', authorization: ['oauth'] },
      { name: 'refresh_token', authorization: ['oauth'] },
      { name: 'expiry', authorization: ['oauth'] },
    ];

    const mapServices = [
      { name: 'bugherd', authorization: 'bearer' },
      { name: 'youtrack', authorization: 'bearer' },
      { name: 'xero', authorization: 'oauth' },
    ];

    const getAuthorizationFields = () => {
      const authFields = [];
      const selectedService = mapServices.find((service) => service.name === form.service);

      if (selectedService) {
        const { authorization } = selectedService;
        mapFields.forEach(({ name, label, authorization: authField }) => {
          if (authField.includes(authorization)) {
            authFields.push({ name, label });
          }
        });
      }

      return authFields;
    };

    setAuthorizationFields(getAuthorizationFields());
  }, [form]);

  return (
    <Form
      name="project-form"
      onFinish={(fieldsValue) => onFinish('connector', fieldsValue, afterSubmit, setLoading)}
      initialValues={model}
      labelCol={{ span: 9 }}
    >
      <Form.Item name="id" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="owner" label="id" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="service" label="Service" rules={[{ required: true }]}>
        <Radio.Group
          value={form.service}
          buttonStyle="solid"
          onChange={
            (value) => onChange({ target: { name: 'service', value: value.target.value } }, setForm)
          }
        >
          {services.map(
            ({ name }) => (
              <Radio.Button
                key={name}
                value={name}
              >
                {capitalize(name)}
              </Radio.Button>
            ),
          )}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="service_url"
        label="Service URL"
        rules={[{
          required: mustDisplayField('service_url'),
          whitespace: true,
          type: 'url',
        }]}
        style={{ display: mustDisplayField('service_url') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="access_token"
        label="Access Token"
        rules={[{ required: mustDisplayField('access_token') }]}
        style={{ display: mustDisplayField('access_token') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="refresh_token"
        label="Refresh Token"
        rules={[{ required: mustDisplayField('refresh_token') }]}
        style={{ display: mustDisplayField('refresh_token') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="expiry"
        label="Expiry"
        rules={[{ required: mustDisplayField('expiry') }]}
        style={{ display: mustDisplayField('expiry') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="bearer_token"
        label="Bearer Token"
        rules={[{ required: mustDisplayField('bearer_token') }]}
        style={{ display: mustDisplayField('bearer_token') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="consumer_key"
        label="Consumer Key"
        rules={[{ required: mustDisplayField('consumer_key') }]}
        style={{ display: mustDisplayField('consumer_key') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="consumer_secret"
        label="Consumer Secret"
        rules={[{ required: mustDisplayField('consumer_secret') }]}
        style={{ display: mustDisplayField('consumer_secret') ? '' : 'none' }}
      >
        <Input />
      </Form.Item>

      <Form.Item key="save">
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

ConnectorForm.defaultProps = {
  afterSubmit: () => {},
};

ConnectorForm.propTypes = {
  afterSubmit: PropTypes.func,
  model: PropTypes.shape({}).isRequired,
};

export default ConnectorForm;

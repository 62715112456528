import React, { useContext } from 'react';
import {
  Select, Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import XeroContext from '../../Context/Xero/XeroContext';

const { Option } = Select;

const XeroSelect = ({ selected, onChange }) => {
  const { dataSource, loading } = useContext(XeroContext);

  return (
    <>
      { loading ? <Skeleton.Input active /> : (
        <Select
          showSearch
          placeholder="Select a contact"
          onChange={onChange}
          value={selected}
          filterOption={(
            input,
            {
              props: { children },
            },
          ) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            dataSource.map(
              ({ ContactID, Name }) => (
                <Option
                  key={ContactID}
                  value={ContactID}
                >
                  {Name}
                </Option>
              ),
            )
          }
        </Select>
      )}
    </>
  );
};

XeroSelect.defaultProps = {
  onChange: () => {},
  selected: null,
};

XeroSelect.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string,

};

export default XeroSelect;

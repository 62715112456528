import React, { useContext, useEffect, useState } from 'react';
import { PageHeader, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import IssuesTable from '../Components/Issue/IssuesTable';
import CommentDrawer from '../Components/Issue/CommentDrawer';
import YoutrackContext from '../Context/Youtrack/YoutrackContext';
import ProjectContext from '../Context/Project/ProjectContext';
import YoutrackIssuesSelectedProvider from '../Context/Youtrack/Issues/Selected/YoutrackIssuesSelectedProvider';

const { Title } = Typography;

const ProjectIssues = () => {
  const { id } = useParams();
  const { dataSource: projects } = useContext(ProjectContext);
  const [issue, setIssue] = useState({});
  const {
    dataSource: ytprojects,
    selected, setSelected: setProjectSelected,
  } = useContext(YoutrackContext);

  let projectName = '';
  let projectRate = '';

  useEffect(() => {
    if (ytprojects.length) {
      setProjectSelected(id);
    }
  }, [ytprojects, id, setProjectSelected]);

  const currentYtProject = ytprojects.find(({ shortName }) => shortName === id);
  const currentProject = projects.find((proj) => proj.youtrack === selected);
  if (currentYtProject) {
    projectName = currentYtProject.name;
  }

  if (currentProject) {
    projectRate = currentProject.rate;
  }

  return (
    <PageHeader
      style={{ width: '100%' }}
      bordered={false}
      title={<Title>{`${projectName} Issues - Rate: $${projectRate}`}</Title>}
      bodyStyle={{ padding: 0 }}
    >
      <YoutrackIssuesSelectedProvider>
        <IssuesTable onClickComment={(selectedIssue) => setIssue(selectedIssue)} />
      </YoutrackIssuesSelectedProvider>
      <CommentDrawer issue={issue} onClose={() => setIssue({})} />
    </PageHeader>

  );
};

export default ProjectIssues;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TimeLog from '../Pages/TimeLog';
import Projects from '../Pages/Projects';
import ProjectIssues from '../Pages/ProjectIssues';
import Integrations from '../Pages/Integrations';
import Users from '../Pages/Users';
import Xero from '../Pages/Xero';
import Redirect from '../Pages/Redirect';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={TimeLog} />
    <Route path="/projects" exact component={Projects} />
    <Route path="/project/:id/issues" exact component={ProjectIssues} />
    <Route path="/integrations" exact component={Integrations} />
    <Route path="/users" exact component={Users} />
    <Route path="/xero" exact component={Xero} />
    <Route path="/redirect/:referrer" exact component={Redirect} />
  </Switch>
);

export default Routes;

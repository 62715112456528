import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import ProjectForm from './ProjectForm';

const ProjectDrawer = ({ initialValues, visible, onClose }) => {
  const { id } = initialValues;
  return (
    <Drawer
      title={`${id ? 'Edit' : 'Create a new'} record`}
      placement="right"
      width={isMobile ? '80%' : '40%'}
      closable
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <ProjectForm afterSubmit={onClose} initialValues={initialValues} />
    </Drawer>
  );
};

ProjectDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    user_id: PropTypes.string,
    duration: PropTypes.string,
  }).isRequired,
};

export default ProjectDrawer;

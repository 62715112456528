import React, { useContext } from 'react';
import {
  Form, Button, Input,
} from 'antd';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import UserContext from '../../Context/User/UserContext';
import { updateUserAttributes } from '../../Helpers/AdminQueries';

const UserForm = ({ model, afterSubmit }) => {
  const {
    me, isAdmin, loading, loadMe, updateUsers,
  } = useContext(UserContext);

  const onFinish = async (fieldsValue) => {
    let response;

    if (isAdmin === true) {
      response = updateUserAttributes(model.id, fieldsValue);
      const updatedUser = {
        ...model,
        sub: model.id,
        ...fieldsValue,
      };
      delete updatedUser.id;

      updateUsers(model.id, updatedUser);
    } else {
      response = await Auth.updateUserAttributes(me, fieldsValue);
      loadMe();
    }

    afterSubmit(response);

    return response;
  };

  return (
    <Form
      name="user-form"
      onFinish={onFinish}
      initialValues={model}
      labelCol={{ span: 6 }}
    >

      <Form.Item name="given_name" label="Given Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="family_name" label="Family Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="nickname" label="Nickname" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="phone_number" label="Phone Number" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      {isAdmin && (
      <Form.Item name="custom:hours_per_week" label="Hours per week" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

UserForm.defaultProps = {
  afterSubmit: () => {},
  model: {
    id: '',
  },
};

UserForm.propTypes = {
  afterSubmit: PropTypes.func,
  model: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default UserForm;

import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Drawer, Card, Divider } from 'antd';
import { isMobile } from 'react-device-detect';

const CommentDrawer = ({ issue, onClose }) => {
  const issueHaveKeys = !!Object.keys(issue).length;
  const { comments, idReadable } = issue;

  const renderComments = () => {
    if (comments) {
      return comments.map(({ id, text, author: { name } }) => (
        <React.Fragment key={`card-comment-${id}`}>
          <Card>
            <p>{ text }</p>
            <small>
              { `Author: ${name}`}
            </small>
          </Card>
          <Divider dashed />
        </React.Fragment>
      ));
    }
    return null;
  };

  return (
    <Drawer
      key={`issue-open-${idReadable}`}
      title="Comments"
      placement="right"
      width={isMobile ? '80%' : '40%'}
      closable
      onClose={onClose}
      visible={issueHaveKeys}
    >
      { renderComments() }
    </Drawer>
  );
};

CommentDrawer.defaultProps = {
  onClose: () => {},
};

CommentDrawer.propTypes = {
  issue: PropTypes.shape({
    comments: PropTypes.arrayOf(PropTypes.shape({})),
    idReadable: string,
  }).isRequired,
  onClose: PropTypes.func,
};

export default CommentDrawer;

import React, { useContext, useState } from 'react';
import {
  Drawer, Skeleton, Typography, PageHeader,
} from 'antd';
import { isMobile } from 'react-device-detect';
import UserContext from '../../Context/User/UserContext';
import UsersTable from './UsersTable';
import UserForm from './UserForm';
import { edit } from '../../Helpers/Card';

const { Title } = Typography;

const UserCard = () => {
  const { loaded } = useContext(UserContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [model, setModel] = useState({});

  const onClickEdit = (event) => {
    const { attributes } = event;
    const user = {
      ...attributes,
      id: attributes.sub,
    };
    delete user.sub;

    edit(
      user,
      setModel,
      setDrawerVisible,
    );
  };

  return (
    <Skeleton loading={!loaded}>
      <PageHeader
        style={{ width: '100%', overflow: 'auto' }}
        bordered={false}
        title={<Title>Users</Title>}
        bodyStyle={{ padding: 0 }}
      >
        <UsersTable
          onClickEdit={onClickEdit}
        />

        <Drawer
          title="Edit record"
          placement="right"
          width={isMobile ? '80%' : '40%'}
          closable
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          destroyOnClose
        >
          <UserForm afterSubmit={() => setDrawerVisible(false)} model={model} />
        </Drawer>
      </PageHeader>
    </Skeleton>
  );
};

export default UserCard;

import React, { useContext } from 'react';
import {
  DeleteOutlined, EditOutlined, PlayCircleOutlined, StopOutlined, WarningOutlined,
} from '@ant-design/icons';
import {
  Button, Popconfirm, Table,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as mutations from '../../graphql/mutations';
import UserContext from '../../Context/User/UserContext';
import { fromSeconds } from '../../Helpers/Duration';
import FlextimeContext from '../../Context/Flextime/FlextimeContext';
import SidebarContext from '../../Context/Sidebar/SidebarContext';

const FlextimesTable = ({ onClickEdit }) => {
  const { isAdmin, me } = useContext(UserContext);
  const { selected } = useContext(SidebarContext);
  const {
    action,
    dataSource,
    deleteModel,
    loading,
  } = useContext(FlextimeContext);

  const start = async () => {
    await action(mutations.createFlextime, { input: {} });
  };

  const stop = async (id) => {
    await action(mutations.updateFlextime, { input: { id } });
  };

  const checkAndPlaceTimerOnItems = (items) => {
    const currentTimer = items.find(
      ({ createdAt, updatedAt }) => moment(updatedAt).diff(moment(createdAt)) < 1000,
    );

    if (!currentTimer) {
      if (!isAdmin || me.username === selected) {
        return [{ id: 'timer-controller' }, ...items];
      }
    }

    return items;
  };

  const renderOperations = (id, row) => (
    <div className="table-ghost-wrapper">
      <Button type="primary" ghost shape="circle" onClick={() => onClickEdit(row)}>
        <EditOutlined />
      </Button>

      <Popconfirm
        title="Are you sure to delete this record?"
        okType="danger"
        icon={<WarningOutlined style={{ color: 'red' }} />}
        onConfirm={() => deleteModel(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="danger" ghost shape="circle">
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  );

  const columns = [
    {
      title: 'Started',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        if (!text) {
          return (
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              loading={loading}
              onClick={start}
            >
              Start
            </Button>
          );
        }

        return moment(text).format('DD MMMM - HH:mm');
      },
    },
    {
      title: 'Stopped',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, row) => {
        const { id, createdAt, updatedAt } = row;
        if (!createdAt) {
          return '';
        }

        if (moment(updatedAt).diff(moment(createdAt)) < 1000) {
          return (
            <Button
              type="danger"
              icon={<StopOutlined />}
              loading={loading}
              onClick={() => stop(id)}
            >
              Stop
            </Button>
          );
        }

        return moment(text).format('DD MMMM - HH:mm');
      },
    },

    {
      title: 'Timed',
      render: (text, row) => {
        const { createdAt, updatedAt } = row;
        if (!createdAt || createdAt === updatedAt) {
          return '';
        }

        const timeDiff = moment(updatedAt).diff(moment(createdAt));

        return fromSeconds(moment.duration(timeDiff).asSeconds());
      },
    },
  ];

  if (isAdmin) {
    columns.push({
      title: 'Operation',
      dataIndex: 'id',
      key: 'operation',
      render: renderOperations,
      align: 'center',
    });
  }

  return (
    <>
      <Table
        style={{ overflow: 'auto' }}
        dataSource={checkAndPlaceTimerOnItems(dataSource)}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};

FlextimesTable.defaultProps = {
  onClickEdit: () => {},
};

FlextimesTable.propTypes = {
  onClickEdit: PropTypes.func,
};

export default FlextimesTable;

import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import IssuesStateOptions from './IssuesStateOptions';

const IssuesStateMultipleSelect = ({ value, onChange }) => (
  <Form.Item>
    <Select
      mode="multiple"
      value={value}
      showSearch
      style={{ width: 200 }}
      placeholder="State"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={
          (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      options={IssuesStateOptions}
    />
  </Form.Item>
);

IssuesStateMultipleSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};

IssuesStateMultipleSelect.defaultProps = {
  onChange: () => {},
};

export default IssuesStateMultipleSelect;

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FlextimeContext from '../../Context/Flextime/FlextimeContext';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import SidebarContext from '../../Context/Sidebar/SidebarContext';
import UserContext from '../../Context/User/UserContext';
import { getItemAttribute } from '../../Helpers/User';
import FractionCircleChart from './FractionCircleChart';
import { FLEXTIME_COLOR } from '../../Constants/Colors';

const ActivityCircleBarChart = ({ period: { start, end } }) => {
  const { me, users, isAdmin } = useContext(UserContext);
  const { dataSource: flextimes } = useContext(FlextimeContext);
  const { dataSource: timeentries } = useContext(TimeEntryContext);
  const { selected } = useContext(SidebarContext);
  const [worked, setWorked] = useState(0);
  const [expected, setExpected] = useState(0);
  const [previousLoad, setPreviousLoad] = useState({});

  useEffect(() => {
    const { selected: prevSelected } = previousLoad;

    const sumFlextimeHoursOnWeek = () => {
      const result = flextimes.reduce((accumulator, { createdAt, updatedAt }) => {
        const current = moment(createdAt);
        if (current.isBetween(start, end)) {
          const timeDiff = moment(updatedAt).diff(moment(createdAt));
          return accumulator + moment.duration(timeDiff).asHours();
        }

        return accumulator;
      }, 0);

      return Math.round(result * 100) / 100;
    };

    const expectedHours = () => {
      if (isAdmin) {
        return getItemAttribute(users, selected, 'custom:hours_per_week');
      }
      return me.attributes['custom:hours_per_week'];
    };

    if (selected !== prevSelected) {
      setExpected(expectedHours());
      setPreviousLoad(selected);
    }

    const w = sumFlextimeHoursOnWeek();
    setWorked(w);
  }, [flextimes, timeentries, me, users, selected, previousLoad, isAdmin, start, end]);

  return (
    <FractionCircleChart
      animated
      id={`FractionCircleChart-${Math.random()}`}
      numerator={worked}
      denominator={expected}
      color={FLEXTIME_COLOR}
    />
  );
};

ActivityCircleBarChart.propTypes = {
  period: PropTypes.shape({
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
  }),
};

ActivityCircleBarChart.defaultProps = {
  period: {
    start: moment(),
    end: moment(),
  },
};

export default ActivityCircleBarChart;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { notification } from 'antd';
import ConnectorContext from '../Connector/ConnectorContext';
import YoutrackContext from './YoutrackContext';

const YoutrackProvider = ({ children }) => {
  const [client, setClient] = useState({});
  const { dataSource: connectors } = useContext(ConnectorContext);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [connector, setConnector] = useState(false);
  const [error, setError] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(false);

  const notifyError = (
    { message: description },
    setLoadingFn = () => {},
    setErrorFn = () => {},
  ) => {
    setErrorFn(true);
    notification.error({
      message: 'Error on YouTrack provider',
      description,
    });
    setLoadingFn(false);
  };

  const request = (url, setDataFn = () => {}, setLoadingFn = () => {}, setErrorFn = () => {}) => {
    if (!error) {
      return client.instance.get(url)
        .then((response) => {
          const { data } = response;
          setLoadingFn(false);
          setDataFn(data);
        })
        .catch((exception) => notifyError(exception, setErrorFn));
    }

    return false;
  };

  const post = async (url, params) => client.instance.post(url, params);

  useEffect(() => {
    if (connectors.length) {
      const currentConnector = connectors.find((item) => item.service === 'youtrack');
      if (currentConnector !== connector) {
        setDataSource([]);
        setClient({});
        setError(false);
        setConnector(currentConnector);
      }
    }

    if (!error) {
      if (!client.instance && connector) {
        const instance = axios.create({
          baseURL: connector.service_url,
          headers: {
            Authorization: `Bearer ${connector.bearer_token}`,
          },
        });

        setClient({ instance });
      }
    }
  }, [client, connector, connectors, dataSource, error]);

  const reload = () => {
    setLoading(true);
    setMounted(true);
    const url = '/api/admin/projects?fields=shortName,name,archived&$top=1000';
    request(url, setDataSource, setLoading, setError);
  };

  if (!mounted && client.instance && !dataSource.length && !error) {
    reload();
  }

  return (
    <YoutrackContext.Provider value={{
      dataSource, loading, selected, setSelected, reload, request, post,
    }}
    >
      {children}
    </YoutrackContext.Provider>
  );
};

YoutrackProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default YoutrackProvider;

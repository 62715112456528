import { createContext } from 'react';

export const defaultValue = {
  loaded: false,
  timeOptions: [],
};

const TimeEntryContext = createContext(defaultValue);

export default TimeEntryContext;

export default [
  {
    label: 'Fixed',
    value: 'Fixed',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'Wait for approve',
    value: '{Wait for approve}',
  },
  {
    label: 'In progress',
    value: '{In progress}',
  },
  {
    label: 'To be discussed',
    value: '{To be discussed}',
  },
  {
    label: 'Closed (Billed)',
    value: '{Closed (Billed)}',
  },
];

import moment from 'moment';

export const toSeconds = (value) => {
  let currentValue = value.replace(/\s/g, '');
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  let index = currentValue.indexOf('d');

  if (index >= 0) {
    days = currentValue.substring(0, index);
    currentValue = currentValue.substring(index + 1, currentValue.length);
  }

  index = currentValue.indexOf('h');

  if (index >= 0) {
    hours = currentValue.substring(0, index);
    currentValue = currentValue.substring(index + 1, currentValue.length);
  }

  index = currentValue.indexOf('m');

  if (index >= 0) {
    minutes = currentValue.substring(0, index);
  }

  index = currentValue.indexOf('s');

  if (index >= 0) {
    seconds = currentValue.substring(0, index);
  }

  return moment.duration(`P0Y0M${days}DT${hours}H${minutes}M${seconds}S`).asSeconds();
};

export const fromSeconds = (seconds) => {
  let count = seconds;
  const minuteDurationInSeconds = 60;
  const hourDurationInSeconds = minuteDurationInSeconds * 60;

  let string = '';

  if (count > hourDurationInSeconds) {
    const hours = Math.floor(count / hourDurationInSeconds);
    string += `${hours}h`;
    count -= hours * hourDurationInSeconds;
  }

  if (count > minuteDurationInSeconds) {
    const minutes = Math.floor(count / minuteDurationInSeconds);
    string += `${minutes}m`;
  }

  return string;
};

export const fromMinutes = (minutes) => {
  const duration = moment.duration(minutes, 'minutes').asSeconds();
  return fromSeconds(duration);
};

const decimalHoursToSeconds = (decimalHours) => moment.duration(decimalHours, 'hours').asSeconds();

export const fromDecimalHours = (decimalHours) => fromSeconds(decimalHoursToSeconds(decimalHours));

export const Duration = {
  toSeconds,
  fromSeconds,
  fromMinutes,
  fromDecimalHours,
};

export default Duration;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YoutrackContext from '../YoutrackContext';
import YoutrackIssuesContext from './YoutrackIssuesContext';
import YoutrackIssuesTypeContext from './Type/YoutrackIssuesTypeContext';

const YoutrackIssuesProvider = ({ children }) => {
  const {
    dataSource: projects, selected: project, request, post,
  } = useContext(YoutrackContext);

  const { selected, type } = useContext(YoutrackIssuesTypeContext);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState('');
  const [state, setState] = useState(['Fixed', 'Approved']);
  const issuesFields = 'numberInProject,idReadable,summary,comments(id,text,author(name)),commentsCount,timeTracking(workItems(id,duration(minutes,presentation))),customFields(projectCustomField(field(name)),value(name,minutes,presentation))';
  const linkedIssuesFields = `links(direction,issues(${issuesFields}),linkType(name))`;
  const fields = `${issuesFields},${linkedIssuesFields}`;
  let url = `/api/issues?fields=${fields}&$top=10000&query=project: ${project} `;

  if (type === 'task' && state && state.length) {
    url += `State: ${state} `;
  }

  if (query) {
    url += ` ${query}`;
  }

  if (selected && selected.length) {
    url += ` Type: ${selected}`;
  }

  useEffect(() => {
    if (project && projects.length) {
      setDataSource([]);
      request(url, setDataSource, setLoading, setError);
    }
  }, [projects, project, url, request, state, selected]);

  const update = (record, params) => {
    const path = `/api/issues/${record.id}?fields=${fields}`;

    post(path, params)
      .then(({ data }) => {
        const newDataSource = dataSource.map((source) => {
          if (source.idReadable === record.id) {
            return data;
          }

          if (source.links && source.links.length) {
            const newLinks = source.links.map((link) => {
              if (link.issues && link.issues.length) {
                const issueIndex = link.issues.findIndex((issue) => issue.idReadable === record.id);
                if (issueIndex !== -1) {
                  const newLink = { ...link };
                  newLink.issues[issueIndex] = data;
                }
              }
              return link;
            });

            const newData = { ...source };
            newData.links = newLinks;

            return newData;
          }

          return source;
        });

        setDataSource(newDataSource);
      })
      .catch((err) => Error.notify(err));
  };

  return (
    <YoutrackIssuesContext.Provider value={{
      dataSource, loading, error, setQuery, state, setState, update,

    }}
    >
      {children}
    </YoutrackIssuesContext.Provider>
  );
};

YoutrackIssuesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default YoutrackIssuesProvider;

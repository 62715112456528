import axios from '../Vendor/Axios/AxiosBugherd.config';

const projects = (id) => axios.get(`/${id}/projects`)
  .then((response) => {
    const { data } = response;
    return data;
  });

const bugherdService = {
  projects,
};

export default bugherdService;

import { API, graphqlOperation } from 'aws-amplify';
import { notification } from 'antd';
import * as mutations from '../../graphql/mutations';
import { getItems } from './Response';

export const deleteRecord = async (key, id) => {
  const response = await API.graphql(graphqlOperation(mutations[key], { input: { id } }));

  const item = getItems(response, key);

  if (typeof item === 'object') {
    notification.success({ message: 'Record deleted' });
    return item;
  }

  return response;
};

export const Mutation = {
  deleteRecord,
};

export default Mutation;

import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import theme from '@amcharts/amcharts4/themes/animated';
import PropTypes from 'prop-types';

const FractionCircleChart = ({
  id, animated, color, size, numerator, denominator,
}) => {
  const [chart, setChart] = useState({});
  const [label, setLabel] = useState({});
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted && !Object.keys(chart).length) {
      if (animated) {
        am4core.useTheme(theme);
      }
      am4core.options.queue = true;
      am4core.options.onlyShowOnViewport = true;
      setChart(am4core.create(`circle-chart-${id}`, am4charts.RadarChart));
    }

    if (!isMounted && Object.keys(chart).length) {
      chart.hiddenState.properties.opacity = 0;

      chart.innerRadius = am4core.percent(80);
      if (size) {
        chart.width = '100%';
        chart.height = size;
      }
      chart.startAngle = -90;
      chart.endAngle = 270;

      // Create axes
      const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      yAxis.dataFields.category = 'category';
      yAxis.renderer.grid.template.strokeOpacity = 0;
      yAxis.renderer.labels.template.disabled = true;
      yAxis.renderer.ticks.template.disabled = true;
      yAxis.renderer.grid.template.disabled = true;
      yAxis.cursorTooltipEnabled = false;

      const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.renderer.grid.template.strokeOpacity = 0;
      xAxis.min = 0;
      xAxis.max = 100;
      xAxis.strictMinMax = true;
      xAxis.renderer.labels.template.disabled = true;
      xAxis.renderer.ticks.template.disabled = true;
      xAxis.renderer.grid.template.disabled = true;

      const series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = 'full';
      series1.dataFields.categoryY = 'category';
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.strokeWidth = 0;

      const series2 = chart.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = 'value';
      series2.dataFields.categoryY = 'category';
      series2.clustered = false;
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.radarColumn.cornerRadius = 20;
      series2.columns.template.adapter.add('fill', (fill, target) => (color ? am4core.color(color) : chart.colors.getIndex(target.dataItem.index)));

      const lbl = chart.radarContainer.createChild(am4core.Label);
      lbl.isMeasured = false;
      lbl.fontSize = 50;
      lbl.horizontalCenter = 'middle';
      lbl.verticalCenter = 'middle';
      setLabel(lbl);
      setIsMounted(true);
    }

    if (isMounted && denominator) {
      chart.data = [{
        category: '',
        value: (numerator * 100) / denominator,
        full: 100,
      }];

      label.html = '<table>'
        + '<tbody>'
        + '<tr>'
        + `<td style="border-bottom:solid 1px">${numerator}</td>`
        + '</tr>'
        + '<tr>'
        + `<td>${denominator}</td>`
        + '</tr>'
        + '</tbody>'
        + '</table>';
    }
  }, [isMounted, numerator, denominator, label, id, chart, size, color, animated]);

  return (
    <div
      id={`circle-chart-${id}`}
      style={{
        width: '100%', maxWidth: size, textAlign: 'center', margin: '0 auto', height: size,
      }}
    />
  );
};

FractionCircleChart.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  numerator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  denominator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  animated: PropTypes.bool,
};

FractionCircleChart.defaultProps = {
  numerator: 0,
  denominator: 40,
  size: 300,
  color: false,
  animated: false,
};

export default FractionCircleChart;
